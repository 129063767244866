import React, { Component } from 'react'
import { Table, Button, Pagination, DatePicker, message,Tabs,Form, Input, Select } from 'antd'
import { listByTime, passedLogListByTime, listTask } from '../../api'
import { exportExcel } from '../../utils/excelUtil'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN'

import "./index.less"

const columns = [
    {
        title: '时间',
        dataIndex: 'createTime',
        key: 'createTime'
    },
    {
        title: '类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '用户姓名',
        dataIndex: 'recommendedName',
        key: 'recommendedName'
    }, {
        title: '用户手机号',
        dataIndex: 'recommendedPhone',
        key: 'recommendedPhone'
    },  {
        title: '推荐人姓名',
        dataIndex: 'referrerName',
        key: 'createTime'
    }, {
        title: '推荐人手机号',
        dataIndex: 'referrerPhone',
        key: 'referrerPhone'
    }
]

const columns2 = [
    {
        title: '时间',
        dataIndex: 'createTime',
        key: 'createTime'
    },
    {
        title: '类型',
        dataIndex: 'candidateInfo',
        key: 'candidateInfo',
        render: (text, record) => (
            <div>开通B端账号</div>
        )
    }, {
        title: '运营人员',
        dataIndex: 'jobName',
        key: 'jobName',
        render: (text, record) => (
            <div>
                <div>{record.jobName} - {record.companyName}</div>
                <div>{record.publishName} - {record.publishPhone}</div>
            </div>
        )
    },{
        title: '明细',
        dataIndex: 'remark',
        render: (text, record) => (
            <div>{record.remark}</div>
        )
    }
]

const columns3 = [
    {
        title: '投递时间',
        dataIndex: 'createTime',
        key: 'createTime'
    },
    {
        title: '求职者',
        dataIndex: 'candidateInfo',
        key: 'candidateInfo',
        render: (text, record) => (
            <div>{record.candidateInfo.name} - {record.candidateInfo.phone}</div>
        )
    }, {
        title: '投递职位',
        dataIndex: 'jobName',
        key: 'jobName',
        render: (text, record) => (
            <div>
                <div>{record.jobName} - {record.companyName}</div>
                <div>{record.publishName} - {record.publishPhone}</div>
            </div>
        )
    },{
        title: '招聘企业',
        dataIndex: 'companyName',
        render: (text, record) => (
            <div>{record.companyName}</div>
        )
    },{
        title: '内推人',
        dataIndex: 'publishPhone',
        render: (text, record) => (
            <div>{record.talentName} { record.talentNam && record.talentPhone ? <span>-</span> : null } {record.talentPhone}</div>
        )
    },  {
        title: '当前进度',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
            <div style={{width: '150px'}}>{getStatus(record.status)}</div>
        )
    }
]

function getStatus (num) {
    let action = new Map([
        [0, '筛选简历'],
        [200, '面试'],
        [300, 'offer'],
        [400, '入职'],
        [10000, '淘汰']
    ])
    return action.get(num)
}

class Statistics extends Component {

    searchRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            pageNum: 1,
            pageSize: 10,
            totalPage: 1,
            totalRecord: 10,
            searchName: '',
            curTime: null, //当前日期
            curTime2: null, //当前日期
            curTime3: null, //当前日期
            localInfo: {},
            status: [
                {id: 1, title: '不限', val: ''},
                {id: 2, title: '投递', val: '0'},
                {id: 3, title: '待面试', val: '200'},
                {id: 4, title: 'offer', val: '300'},
                {id: 5, title: '通过面试', val: '400'},
                {id: 6, title: '淘汰', val: '10000'}
            ],
            taskInfo: {},
            taskCountInfo: {}
        }
    }

    componentDidMount() {
        let date = this.onGetCurrentTime()
        this.setState({curTime: date, curTime2: date, curTime3: date})
        this.onListByTime(10, 0 , date)
        this._passedLogListByTime(0, 10, date)
    }

    componentWillUnmount () {
        this.onClearTime()
    }

   
    
    handleGoEditeCompany = () => {
        this.props.history.push({ pathname: '/editeCompany' })
    }

    //获取当前日期
    onGetCurrentTime () {
        let date = new Date()
        let year = date.getFullYear()
        let month = date.getMonth()
        month = (month + 1) >= 10 ? (month + 1) : '0' + (month + 1)
        let day = date.getDate()
        let str = year + "-" + month + "-" + (day < 10 ?  '0' + day : day)
        return str;
    }

    async  _passedLogListByTime ( start = 0, limit = 10, time = '') {
        let res = await passedLogListByTime({start, limit, data: time})
        this.setState({
            taskCountInfo: {
                data: res.data, 
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalRecord: res.totalRecord
            }
        })
    }


    // 推广列表
    async onListByTime (limit = 10, start = 0, time = '') {
        let res = await listByTime({limit , start , data: time})
        console.log(res,'这里啦...')
        this.setState({
            dataSource: res.data,
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            totalPage: res.totalPage,
            totalRecord: res.totalRecord,
            curTime: time
        })
    }

    handleChange (current, pageSize) {
        this.onListByTime(pageSize, (current - 1) * pageSize , this.state.curTime)
    }

    handleChange2 (current, pageSize) {
        const status = this.searchRef.current.getFieldValue('status')
        const name = this.searchRef.current.getFieldValue('name')
        const time = this.searchRef.current.getFieldValue('time')
        this.passedLogListByTime((current - 1) * pageSize, pageSize, this.state.curTime2)
    }

    handleChange3 (current, pageSize) {
        const status = this.searchRef.current.getFieldValue('status')
        const name = this.searchRef.current.getFieldValue('name')
        let time = this.searchRef.current.getFieldValue('time')
        time = time ?  moment(time).format('YYYY-MM-DD') : undefined
        this._listTask({
            status, name, time
        }, (current - 1) * pageSize, pageSize)
    }

    //导出excel
    onExportExcel () {
        const { dataSource, curTime } = this.state
        if (dataSource.length === 0) {
            message.error("暂无数据，请勿操作!")
            return
        }
        exportExcel(columns, dataSource, `${curTime}.xlsx`)
    }

     //导出excel
     onExportExcel2 () {
        const { taskCountInfo, curTime } = this.state
        if (taskCountInfo.data.length === 0) {
            message.error("暂无数据，请勿操作!")
            return
        }
        exportExcel(columns2, taskCountInfo.data, `${curTime}.xlsx`)
    }

    onChangeCurTime(e) {
        let curTime = moment(e).format('YYYY-MM-DD')
        this.onListByTime(10, 0, curTime)
    }

    onChangeCurTime2(e) {
        let curTime = moment(e).format('YYYY-MM-DD')
        this._passedLogListByTime(0,10, curTime)
    }

    callback (key) {
        const {  curTime3 } = this.state
        /* if (key == 3) {
            let times = setTimeout(() => {
                clearTimeout(times)
                this.searchRef.current.setFieldsValue({time: moment(curTime3, 'YYYY-MM-DD')})
            }, 1000)
            
        } */
        console.log(key)
    }

    async _listTask (data, start = 0,limit = 10) {
        const res = await listTask({ start, limit, data: {...data}})
        this.setState({taskData: res.data})
        this.setState({taskInfo: {
            data: res.data, 
            pageNum: res.pageNum,
            pageSize: res.pageSize,
            totalRecord: res.totalRecord
        }})
    }

    onSearchTask (values) {
        if(values.time) {
            values.time = moment(values.time).format('YYYY-MM-DD')
        }
        this._listTask(values)
    }

    render() {
        const { dataSource, totalRecord, pageNum, curTime, curTime2, taskInfo, taskCountInfo } = this.state
        return (
            <div className='statistics-page'>
                <Tabs defaultActiveKey="1" onChange={this.callback.bind(this)}>
                    <Tabs.TabPane tab="新增用户" key="1">
                        <div className='statistics-tool-bar'>
                            <div>
                                {
                                    !curTime ? null :  (
                                        <DatePicker 
                                        defaultValue={moment(curTime, 'YYYY-MM-DD')} 
                                        locale={locale} 
                                        onChange={this.onChangeCurTime.bind(this)} />
                                    )
                                }
                            </div>
                            <div className='btn'>
                                <Button onClick={this.onExportExcel.bind(this)} type='primary'>导出当前日期数据</Button>
                            </div>
                        </div>
                        <Table
                            rowKey={(record) => record.id}
                            dataSource={this.state.dataSource}
                            columns={columns}
                            locale={{emptyText: '暂无数据'}}
                            pagination={false} />
                        <div className='pagination-box'>
                            {
                                totalRecord > 10 ? <Pagination defaultCurrent={pageNum} 
                                total={totalRecord} 
                                onChange={this.handleChange.bind(this)} /> : null
                            }
                            
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="地推统计" key="2">
                        <div className='statistics-tool-bar2'>
                            <div>
                                {
                                    !curTime2 ? null :  (
                                        <DatePicker 
                                        defaultValue={moment(curTime2, 'YYYY-MM-DD')} 
                                        locale={locale} 
                                        onChange={this.onChangeCurTime2.bind(this)} />
                                    )
                                }
                            </div>
                            <div className='btn'>
                                <Button onClick={this.onExportExcel2.bind(this)} type='primary'>导出当前日期数据</Button>
                            </div>
                        </div>
                        <Table
                                rowKey={(record) => record.id}
                                dataSource={taskCountInfo.data}
                                columns={columns2}
                                locale={{emptyText: '暂无数据'}}
                                pagination={false} />
                        <div className='pagination-box'>
                            <Pagination defaultCurrent={taskCountInfo.pageNum} 
                                total={taskCountInfo.totalRecord} 
                                hideOnSinglePage={true}
                                onChange={this.handleChange2.bind(this)} />
                        </div>        
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="投递明细" key="3">
                        <div style={{marginBottom: '10px'}}>
                            <Form
                                ref={this.searchRef}
                                layout={'inline'}
                                onFinish={this.onSearchTask.bind(this)}
                            >
                                <Form.Item label="当前进度" name="status">
                                    <Select style={{ width: 120 }} placeholder='不限'>
                                        {
                                            this.state.status.map((item) => {
                                                return (
                                                    <Select.Option 
                                                        key={item.id} 
                                                        value={item.val}>
                                                            { item.title }
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                        
                                    </Select>
                                </Form.Item>

                                <Form.Item name="candidateName" label="求职者">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="投递日期" name="time">
                                    <DatePicker 
                                        locale={locale}  />
                                </Form.Item>
                                <Form.Item name="companyName" label="招聘企业">
                                    <Input />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit">搜索</Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div>
                            <Table
                                rowKey={(record) => record.id}
                                dataSource={taskInfo.data}
                                columns={columns3}
                                locale={{emptyText: '暂无数据'}}
                                pagination={false} />
                        </div>
                        <div className='pagination-box'>
                            <Pagination defaultCurrent={taskInfo.pageNum} 
                                total={taskInfo.totalRecord} 
                                hideOnSinglePage={true}
                                onChange={this.handleChange3.bind(this)} />
                        </div>
                    </Tabs.TabPane>
                </Tabs>
                
            </div>
        )
    }
}

export default Statistics