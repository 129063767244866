import React, { Component } from 'react'
import '../assets/css/header.less'
import { Modal, message } from 'antd'
import { accountLogout } from '../api'


const { confirm } = Modal

class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userInfo: {},
            menu: [{
                    id: (Math.random() * 10000).toFixed(0),
                    title: "公司管理",
                    path: "/company",
                    active: false
                },
                {
                    id: (Math.random() * 10000).toFixed(0),
                    title: "B端账号管理",
                    path: "/account",
                    active: false
                },
                {
                    id: (Math.random() * 10000).toFixed(0),
                    title: "运营统计",
                    path: "/statistics",
                    active: false
                },
                {
                    id: (Math.random() * 10000).toFixed(0),
                    title: "财务管理",
                    path: "/finance",
                    active: false
                },
                {
                    id: (Math.random() * 10000).toFixed(0),
                    title: "活动运营",
                    path: "/activities",
                    active: false
                },
                {
                    id: (Math.random() * 10000).toFixed(0),
                    title: "权限分配",
                    path: "/role",
                    active: false
                }
            ],
            menu2: [{
                    id: (Math.random() * 10000).toFixed(0),
                    title: "公司管理",
                    path: "/company",
                    active: false
                },
                {
                    id: (Math.random() * 10000).toFixed(0),
                    title: "B端账号管理",
                    path: "/account",
                    active: false
                }
            ],
            menu3: []
        }
    }

    componentDidMount() {
        let user = sessionStorage.getItem('U')
        if (user != null && user !== '') {
            user = JSON.parse(user)
            this.setState({ userInfo: user })
        }
        this.handleMenu()
    }



    handleLogoutSub = async() => {
        try {
            let { code } = await accountLogout()
            if (code === 0) {
                message.success('退出成功¡')
                sessionStorage.removeItem('U')
                setTimeout(() => window.location.href = '/login', 2000)
            } else {
                message.error('退出失败!')
                return
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleLogout = () => {
        let _self = this
        confirm({
            title: '温馨提示',
            content: '你确定要退出吗?',
            cancelText: '取消',
            okText: '确定',
            onOk() {
                _self.handleLogoutSub()
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }

    handleMenu() {
        let path = window.location.href
        let { menu, menu2 } = this.state
        let local = sessionStorage.getItem('U')
        local = JSON.parse(local)
        if (local.system) {
            menu.forEach(item => {
                if (path.includes(item.path)) {
                    item.active = true
                }
            })
            this.setState({menu3 : menu})
        } else {
            menu2.forEach(item => {
                if (path.includes(item.path)) {
                    item.active = true
                }
            })
            this.setState({menu3 : menu2})
        }
        
    }


    render() {
        const { userInfo, menu3 } = this.state
        return ( <div className = 'header-box'>
                <div className = 'left-box'>
                <div className = 'logo-box'>
                <img alt = "logo"
                src = "https://img.fanwoon.com/new_logo.png" />
                </div> {
                menu3.map((item, index) => {
                    return ( <a className = { `m-lr-8 black-color ${item.active ? 'active-menu' : ''}` }
                        href = { item.path }
                        key = { index } > { item.title } </a>
                    )
                })
            } </div> 
            <div>
                <span className = 'm-r-16 default-color' > { userInfo.name } {userInfo.account !== 'administrator' ? (`(工号：${userInfo.empNo})`) : ''} </span> 
                <span className = 'cursors'
                onClick = { this.handleLogout } > 退出 </span> 
                </div> 
            </div>
    )
}
}

export default Header