import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect, router } from 'react-router-dom'
import { message } from 'antd'
import Account from '../views/account'
import Company from '../views/company'
import Login from '../views/system/login'
import EditeCompany from '../views/company/edite'
import Statistics from '../views/statistics'
import Finance from '../views/finance'
import Activities from '../views/activities'
import Role from '../views/role/index'


const routesList = [
    {
        path: '/company',
        exact: true,
        isAuthor: true,
        isSystem: true,
        component: Company,
    },
    {
        path: '/editeCompany',
        exact: true,
        isAuthor: true,
        component: EditeCompany,
    },
    {
        path: '/account',
        exact: true,
        isAuthor: true,
        isSystem: true,
        component: Account,
    },
    {
        path: '/statistics',
        exact: true,
        isAuthor: true,
        component: Statistics,
    },
    {
        path: '/finance',
        exact: true,
        isAuthor: true,
        component: Finance,
    },
    {
        path: '/activities',
        exact: true,
        isAuthor: true,
        component: Activities,
    },
    {
        path: '/role',
        exact: true,
        isAuthor: true,
        component: Role,
    }
]

const _init = () => {
    let token = sessionStorage.getItem('U');

    let pathname = window.location.pathname
    const data = routesList.map((item) => item.isSystem ? item.path : null)

    if (token != null && token !== '') {
        token = JSON.parse(token)
        if (!token.system && !data.includes(pathname)) {
            message.error('无权访问!')
            window.history.back(-1)
            return false
        }
    }
    return true
}

class RouterHome extends Component {


    render() {
        let auth = ''
        let token = sessionStorage.getItem('U');
        if (token != null && token !== '') {
            token = JSON.parse(token)
            auth = token
        }
       let bool = _init()
       if (!bool)return <div></div>

        const { showHeader } = this.props
        return (
            <BrowserRouter>
                <Switch>
                    <Route path='/' exact render={() => (
                        <Redirect to='/login'></Redirect>
                    )}></Route>
                    <Route path='/login' component={Login} />
                    {
                        routesList.map((item, index) => {
                            if (item.isAuthor && (auth != null && auth !== '')) {
                                showHeader()
                                return <Route exact key={index} path={item.path} component={item.component} />
                            } else {
                                return <Redirect key={index} to='/login' />

                            }
                        })
                    }
                </Switch>
            </BrowserRouter>
        )
    }
}

export default RouterHome