import React, { Component } from 'react'
import {
    Input, Modal, Form, Radio, Button, message,
    Select
} from 'antd'
import {
    listLikenameByCompany, accountVerified
} from '../api'

import { debounce } from '../utils'


class AccountForm extends Component {

    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            companyList: [],
            visible: true,
            searchName: '',
        }
        this.handleSearchCompany = this.handleSearchCompany.bind(this)
    }

    componentDidMount() {
        let timeout = setTimeout(() => {
            clearTimeout(timeout)
            timeout = null
            let { account } = this.props
            //this.formRef.current.setFieldsValue({ company: account.companyName })
            this.formRef.current.setFieldsValue({ password: account.password })
            this.formRef.current.setFieldsValue({ system: account.system })
        }, 500)
    }

    highLightKeywords = (text, keyword) => {
        // 默认的标签，如果没有指定，使用span
        let reg = new RegExp("(" + keyword + ")", "g")
        let newStr = text.replace(reg, `<span style='color:#E6A23C;'>${keyword}</span>`)
        return newStr
    }

    handleSearchCompany = debounce ((name) => {
        let _self = this
        if (!name) return
        listLikenameByCompany({ limit: 10, start: 0, data: name }).then(res => {

                res.data.forEach((item) => {
                    item['newWords'] = _self.highLightKeywords(item.shortName, name) + `(${_self.highLightKeywords(item.name, name)})`
                })
                console.log(res.data, '处理过后的...')
            this.setState({ companyList: res.data })
        })
    }, 1000)

    handleCancel = () => {
        this.props.parent.getChildrenMsg(this)
    }

    async onFinish(values) {
        console.log(values, '表单')
        let { account, parent, modalForm } = this.props
        let company = ''
        let data = {
            gender: account.gender,
            id: account.id,
            jobGrade: account.jobGrade,
            name: account.name,
            phone: account.phone,
            referrer: account.referrer,
            system: values.system,
            code: 9999
        }

        if(values.password) {
            data['password'] = values.password
        }
        
        //debugger
        if (values.company.includes('{')) {
            company = values.company
            company = JSON.parse(company)
            data['companyId'] = company.id
            data['companyName'] = company.name//account.companyName
            data['shortName'] = company.shortName//company.name
        } else {
            data['companyId'] = account.companyId
            data['companyName'] = account.companyName
            data['shortName'] = account.shortName
        }
        
        parent.getChildrenMsg(this)
        if (data.companyId === 0) {
            message.error("该公司不存在，请先创建公司后再开通企业账号")
            return
        }
        //console.log(data)
        let res = await accountVerified(data)
        if (res.code === 0) {
            let msg = modalForm === 0 ? '账号开通成功!' : '编辑账号成功!'
            message.success(msg)
            modalForm === 0 ? parent.handleRegisterList() : parent.handleAccountListSearch()

        } else {
            let msg = modalForm === 0 ? '账号开通失败!' : '编辑账号失败!'
            message.error(msg)
        }
    }


    render() {
        const {companyList } = this.state
        const { account, modalForm } = this.props

        return (
            <div className='account-page'>
                <Modal
                    title={Number(modalForm) === 0 ? '开通账号' : '编辑账号'}
                    visible={this.state.visible}
                    footer={null}
                    onCancel={this.handleCancel}>

                    <Form
                        ref={this.formRef}
                        layout="vertical"
                        onFinish={this.onFinish.bind(this)}>
                        <div>{account.name} - {account.shortName} - {account.jobGrade}</div>
                        <div>公司名称:{account.companyName}</div>
                        <div>{account.phone}</div>
                        <div>推荐人：{!account.referrer ? '未填写' : account.referrer}</div>
                        <Form.Item
                            label='所属公司'
                            name='company'
                            rules={[{ required: true, message: '请输入牌匾名or公司名!' }]}>
                            <Select
                                showSearch
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                placeholder='搜索支持:牌匾名or公司名'
                                onSearch={this.handleSearchCompany}>
                                {
                                    companyList.map((item, index) => {
                                        return <Select.Option key={index} value={JSON.stringify({ id: item.id, name: item.name,shortName: item.shortName })}>
{/*                                             {item.shortName} */}
                                            <span dangerouslySetInnerHTML={{__html: item.newWords}}></span>
                                         </Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={Number(modalForm) === 0 ? '初始密码' : '重置密码'}
                            name='password'
                            rules={[{ required: Number(modalForm) === 0 ? true : false, message: '请输入密码!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='角色'
                            name='system'
                            rules={[{ required: true, message: '请选择角色!' }]}>
                            <Radio.Group>
                                <Radio value={true}>管理员</Radio>
                                <Radio value={false}>普通用户</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item>
                            <div className='account-footer'>
                                <Button className='m-r-16' type="primary" htmlType="submit">
                                    确认
                                </Button>
                                <Button onClick={this.handleCancel}>取消</Button>
                            </div>
                        </Form.Item>
                    </Form>

                </Modal>
            </div>
        )
    }
}

export default AccountForm