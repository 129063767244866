import React, { Component } from "react";
import {
    Form,
    Upload,
    message,
    Input,
    Select,
    Modal,
    Cascader,
    Button,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getQueryString } from "../../utils";
import {
    population,
    storeSize,
    corporateWelfare,
    provinceMap,
    companyDetail,
    companySave,
    companyUpdate,
    uploadBase64,
} from "../../api";
import "./index.less";

const { Option } = Select;
const { TextArea } = Input;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
}

let config = { path: "companyLogo" };
let OMS = "";
if (process.env.NODE_ENV === "development") {
    OMS = "/api";
} else {
    OMS = "https://api.fanwoon.com/oms";
}

let uploadPath = `${OMS}/upload/uploadImage`;

const checkShortName = (rule, value) => {
    if (value && value.length > 30) {
        return Promise.reject("最多不超过30个字符!");
    }
    return Promise.resolve();
};

const validatorCity = (rule, value) => {
    if (!value) return Promise.reject("请选择省市区!");
    return Promise.resolve();
};

class EditeCompany extends Component {
    formRef = React.createRef();

    constructor() {
        super();
        this.state = {
            info: {
                gallery: [],
            },
            populationList: [],
            storeSizeList: [],
            corporateWelfareList: [],
            provinceMapList: [],

            previewVisible: false,
            previewImage: "",
            previewTitle: "",
            populationSize2: "",
            isModalVisible: false,
            logos: [{
                    id: 1,
                    title: "餐饮",
                    logo: "https://img.fanwoon.com/oms_icon1.png",
                    checked: false,
                },
                {
                    id: 2,
                    title: "健身",
                    logo: "https://img.fanwoon.com/oms_icon2.png",
                    checked: false,
                },
                {
                    id: 3,
                    title: "丽人",
                    logo: "https://img.fanwoon.com/oms_icon3.png",
                    checked: false,
                },
                {
                    id: 4,
                    title: "外卖",
                    logo: "https://img.fanwoon.com/oms_icon4.png",
                    checked: false,
                },
                {
                    id: 5,
                    title: "零售/商超/卖场",
                    logo: "https://img.fanwoon.com/oms_icon5.png",
                    checked: false,
                },
                {
                    id: 6,
                    title: "快递/物流",
                    logo: "https://img.fanwoon.com/oms_icon6.png",
                    checked: false,
                },
                {
                    id: 7,
                    title: "网约车",
                    logo: "https://img.fanwoon.com/oms_icon7.png",
                    checked: false,
                },
                {
                    id: 8,
                    title: "酒店",
                    logo: "https://img.fanwoon.com/oms_icon8.png",
                    checked: false,
                },
                {
                    id: 9,
                    title: "培训",
                    logo: "https://img.fanwoon.com/oms_icon9.png",
                    checked: false,
                },
                {
                    id: 10,
                    title: "其他",
                    logo: "https://img.fanwoon.com/oms_icon10.png",
                    checked: false,
                },
            ],
            logoStr: "",
            logoStrText: "",
        };
        this.onFinish = this.onFinish.bind(this);
        this.handleCompanyDetail = this.handleCompanyDetail.bind(this);
    }

    componentDidMount() {
        // this.onShowLogo('2')
        this.handlePopulation();
        this.handleStoreSize();
        this.handleCorporateWelfare();
        this.handleProvinceMap();
        let companyId = getQueryString("id");
        if (companyId) {
            this.handleCompanyDetail(companyId);
        }
    }

    async update64(url) {
        let res = await uploadBase64(url);
        let pic = res.data;
        return pic;
    }

    handleProvinceMap() {
        provinceMap().then((res) => this.setState({ provinceMapList: res.data }));
    }

    handleCompanyDetail(id) {
        companyDetail({ data: id }).then((resonse) => {
            console.log(resonse.data, "d...");
            let res = resonse.data;

            if (res.gallery) {
                res.gallery = res.gallery.split(",");
                res.gallery = res.gallery.map((item) => {
                    return {
                        uid: (Math.random() * 10000).toFixed(0),
                        name: "公司图片展示",
                        status: "done",
                        url: item,
                    };
                });
            } else {
                res.gallery = [];
            }
            this.setState({ info: res });
            this.formRef.current.setFieldsValue({ name: res.name });
            this.setState({ populationSize2: res.populationSize });
            this.formRef.current.setFieldsValue({
                populationSize: res.populationSize,
            });
            this.formRef.current.setFieldsValue({ shortName: res.shortName });
            this.formRef.current.setFieldsValue({ storeSize: res.storeSize });
            this.formRef.current.setFieldsValue({ introduction: res.introduction });
            this.formRef.current.setFieldsValue({ hqAdd: res.hqAdd });
            this.formRef.current.setFieldsValue({
                cityMap: [res.provinceId, res.cityId, res.districtId],
            });
            if (!res.corporateWelfare) return false;
            if (res.corporateWelfare.includes("[")) {
                this.formRef.current.setFieldsValue({
                    corporateWelfare: JSON.parse(res.corporateWelfare),
                });
            } else if (res.corporateWelfare.includes("，")) {
                this.formRef.current.setFieldsValue({
                    corporateWelfare: res.corporateWelfare.split("，"),
                });
            } else {
                this.formRef.current.setFieldsValue({
                    corporateWelfare: res.corporateWelfare.split(","),
                });
            }
        });
    }

    handleCorporateWelfare() {
        corporateWelfare().then((res) =>
            this.setState({ corporateWelfareList: res.data })
        );
    }

    handleStoreSize() {
        storeSize().then((res) => {
            this.setState({ storeSizeList: res.data });
        });
    }

    handlePopulation() {
        population().then((res) => {
            this.setState({ populationList: res.data });
        });
    }

    handleChange = (infos) => {
        if (infos.file.status === "uploading") {
            this.setState({ loading: true });
            return;
        }
        if (infos.file.status === "done") {
            // Get this url from response in real world.
            let res = infos.file.response.data;
            let { info } = this.state;
            info["logo"] = res;
            this.setState({
                loading: false,
                info,
            });
        }
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async(file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        });
    };

    handleGoCompany = () => {
        this.props.history.push({ pathname: "/company" });
    };

    /**
     * @description 获取省市区各个的编号以及名称
     * @param {*} cityMap
     */
    handleGetCityInfo(cityMap) {
        console.log(cityMap);
        let { provinceMapList } = this.state;
        let provinceId = "";
        let province = ""; //省
        let cityId = ""; //市id
        let city = "";
        let districtId = "";
        let district = "";

        provinceMapList.forEach((p, index) => {
            if (p.id === cityMap[0]) {
                console.log(p.id, p.name);
                province = p.name;
                provinceId = p.id;
            }
            p.children.forEach((c, cindex) => {
                if (c.id === cityMap[1]) {
                    city = c.name;
                    cityId = c.id;
                }
                c.children.forEach((d, dindex) => {
                    if (d.id === cityMap[2]) {
                        district = d.name;
                        districtId = d.id;
                    }
                });
            });
        });
        return { provinceId, province, cityId, city, districtId, district };
    }

    onFinish = async(params) => {
        let { info } = this.state;

        if (!!params.corporateWelfare) {
            params.corporateWelfare = params.corporateWelfare.join(",");
        }
        let data = {...params };
        data["status"] = info.status || 1;
        if (info.id) {
            data["id"] = info.id;
        }

        data["logo"] = info.logo;
        data["gallery"] = info.gallery;
        let str = data.gallery.map((item) => item.url);
        if (str) {
            data.gallery = str.join(",");
        }

        if (!data["logo"]) {
            message.error("请上传公司logo，或选择默认logo");
            return;
        }
        /*  console.log(data, "打印了。。。。");
                  return ; */
        let res = "";
        data["id"] ?
            (res = await companyUpdate(data)) :
            (res = await companySave(data));

        if (res.code === 0) {
            let str = info.id ? "编辑企业成功!" : "新增企业成功!";
            message.success(str);
            let timeous = window.setTimeout(() => {
                window.clearTimeout(timeous);
                timeous = null;
                window.location.href = "/company";
            }, 2000);
            return;
        } else {
            message.error(info.id ? "编辑企业失败!" : "新增企业失败!");
        }
    };

    //处理公司预览图
    handleChange2 = (infos) => {
        if (infos.file.status === "uploading") {
            this.setState({ loading: true });
            return;
        }

        if (infos.file.status === "done") {
            // Get this url from response in real world.
            this.setState({
                loading: false,
            });
        }
    };

    onUpMore(e) {
        console.log(e, "成功回调。。。");
        let picObj = {
            uid: (Math.random() * 10000).toFixed(0),
            name: "公司图片展示",
            status: "done",
            url: e.data,
        };
        let { info } = this.state;
        if (info["gallery"] && typeof info["gallery"] !== "string") {
            info["gallery"].push(picObj);
        } else if (!info["gallery"]) {
            info["gallery"].push(picObj);
        }

        this.setState({ info });
    }

    //移除公司预览图图片组
    onRemove(e) {
        let { info } = this.state;
        info.gallery.forEach((item, index) => {
            if (item.uid === e.uid) {
                info.gallery.splice(index, 1);
            }
        });
        this.setState({ info });
        console.log(e);
    }

    onShowLogo(shortName) {
        let canvas = document.getElementById("canvas");
        let cxt = canvas.getContext("2d");

        let colors = ["#F9005D", "#FF2F3D", "#FFA100"];
        let color = colors[Math.floor(Math.random() * colors.length)];

        /* //画一个实心圆
                cxt.beginPath();
                cxt.arc(50, 50, 50, 0, 360, false);
                cxt.fillStyle = color; //填充颜色,默认是黑色
                cxt.fill(); //画实心圆
                cxt.closePath(); */
        cxt.lineWidth = 150;
        cxt.fillStyle = color; //填充颜色,默认是黑色
        cxt.fillRect(0, 0, 100, 100);

        cxt.font = "40px Arial";
        cxt.fillStyle = "#fff";
        let talentName = shortName;
        talentName = talentName ? talentName.substring(0, 1) : null;
        cxt.textAlign = "center";
        console.log(canvas.width, "kuan.");
        cxt.fillText(talentName, canvas.width / 2, 63, 100);
        let pic = document.getElementById("canvas").toDataURL("image/png");
        console.log(pic, "打印了啥");
        return pic;
    }

    onTogglePic = () => {
        this.setState({ isModalVisible: !this.state.isModalVisible });
    };

    render() {
        const {
            loading,
            populationList,
            storeSizeList,
            corporateWelfareList,
            provinceMapList,
            previewVisible,
            previewImage,
            previewTitle,
            info,
        } = this.state;
        const uploadButton = ( 
            <div>

            { loading ? <LoadingOutlined /> : <PlusOutlined /> } 
            <div style = {
                { marginTop: 8 }
            } > Upload </div> </div>
        );

        const uploadButton2 = ( 
            <div>
                <PlusOutlined />
                <div style = {{ marginTop: 8 }} > Upload </div> 
            </div>
        );

        return ( 
            <div className = "edite-page">
            <canvas id = "canvas"
            width = "100"
            height = "100" />
            <Form ref = { this.formRef }
            initialValues = { this.state.formInitValues }
            onFinish = { this.onFinish } >
            <div className = "edite-box" >
                <div className = "edite-box-item-left" >
                <Upload name = "file"
                headers = { config }
                action = { uploadPath }
                listType = "picture-card"
                showUploadList = { false }
                beforeUpload = { beforeUpload }
                onChange = { this.handleChange }
                withCredentials = { true }> {
                    info.logo ? ( <img src = { info.logo }
                        alt = "avatar"
                        style = {
                            { width: "100%" }
                        }
                        />
                    ) : (
                        uploadButton
                    )
                } </Upload> 
                <div>
                使用默认logo： { this.state.logoStrText } 
                <span className = "chooseBtn"
                onClick = {
                    () => this.onTogglePic()
                } >
                选择 </span> 
                </div>
                </div> 
                <div className = "edite-box-item-right" >
                    <Form.Item label = "公司名称"
                    name = "name"
                    rules = {
                        [{ required: true, message: "公司名称不能为空!" }]
                    } >
                        <Input />
                    </Form.Item> 
                </div> 
                <div className = "edite-box-item-right">
                    <Form.Item label = "线下门店牌匾名"
                    name = "shortName"
                    rules = {
                        [{ required: true, validator: checkShortName }]
                    } >
                        <Input />
                    </Form.Item> 
                </div> 
            </div> 
            <div className = "article-item-box" >
            <div className = "title-blod" > 公司展示(最多5张图片) </div> 
            <Form.Item>
            <Upload name = "file"
            headers = { config }
            action = { uploadPath }
            listType = "picture-card"
            showUploadList = { true }
            fileList = { info.gallery }
            beforeUpload = { beforeUpload }
            onSuccess = { this.onUpMore.bind(this) }
            onRemove = { this.onRemove.bind(this) } > {!info.gallery ?
                uploadButton2 : info.gallery.length >= 5 ?
                    null : uploadButton2
            } </Upload> 
            </Form.Item> 
            <Form.Item>
                <Modal visible = { previewVisible }
                    title = { previewTitle }
                    footer = { null }
                    onCancel = { this.handleCancel } >
                    <img alt = "example"
                    style = {
                        { width: "100%" }
                    }
                    src = { previewImage }
                    /> 
                </Modal> 
            </Form.Item> 
            </div> 
            <div className = "article-item-box">
            <div className = "title-blod" > { /*  <label className="mark-star"> * </label> */ }
            公司介绍 
            </div> 
            <Form.Item name = "introduction"
            rules = {
                [{ required: false, message: "公司介绍不能为空!" }]
            } >
            <TextArea placeholder = "该门店经过「翻腕」线下核实，招聘信息真实有效。在求职过程中， 如发现包括但不限于扣押求职者证件、 收取求职者财物、 向求职者集资、 让求职者入股、 诱导求职者异地入职、 异地参与培训等行为， 请立即向「 翻腕」 客服举报。..."
            rows = { 8 }>
            </TextArea> 
            </Form.Item> 
            </div> 
            <div className = "form-footer-box" >
            <Button className = "m-r-16"
            type = "primary"
            htmlType = "submit">
            提交 </Button> <Button onClick = { this.handleGoCompany } > 取消 </Button> 
            </div> 
            </Form> 
            <Modal width = "600px"
            title = "选择默认LOGO（点击图片选择）"
            footer = { null }
            visible = { this.state.isModalVisible }
            closable = { false } >
            <ul className = "company-logo-list" > 
            {
                this.state.logos.map((item, index) => {
                    return ( <li key = { index }
                        className = "company-logo-list-item"
                        onClick = {
                            () => {
                                this.setState({
                                    logoStr: item.logo,
                                    logoStrText: item.title,
                                    info: {...this.state.info, logo: item.logo },
                                });
                                this.onTogglePic();
                            }
                        } >
                        <img className = "company-logos"src = { item.logo }/> 
                        <span className = "company-logo-title" > { item.title } </span> </li>
                    );
                })
            } 
                </ul> 
                <div className = "company-logo-box">
                    <button className = "company-logo-btn"onClick = {() => this.onTogglePic()} >取消 </button> 
                </div> 
            </Modal> 
          </div>
        );
    }
}

export default EditeCompany;