import React, { Component } from 'react';


import RouterHome from './router/index'
import Header from './components/Header'
import 'antd/dist/antd.less'
import './App.css';


class App extends Component {

  /* constructor(props) {
    super(props)
  } */

  render() {
   // const { value, onIncreaseClick } = this.props
   const { heaer } = this.props;
    return (
      <div className="App">
        {
          heaer ? <Header /> : null
        }
        <RouterHome { ...this.props } />
      </div>
    );
  }
}

export default App;
