import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Button, Pagination, message, Modal, Form, Select, DatePicker } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {  debounce } from "../../utils";
import {
    recommendCompany,
    listLikenameByCompany,
    setRecommend
} from "../../api"
import moment from 'moment'

import "./index.less"
import locale from 'antd/es/date-picker/locale/zh_CN'
import { Tabs } from 'antd'
const { TabPane } = Tabs
const { confirm } = Modal

const Index = () => {


    let columns = [
        {
          title: "排序",
          dataIndex: "recommend",
          key: "recommend",
        },
        {
            title: "公司名称",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <div style={{ width: `150px` }}>{record.name}</div>
              ),
          },
        {
          title: "logo",
          dataIndex: "logo",
          render: (text, record) => (
            <div>
              <img className="logos" src={record.logo} alt={record.shortName} />
            </div>
          ),
        },
        {
            title: "线下门店牌匾名",
            dataIndex: "shortName",
            key: "shortName",
            render: (text, record) => (
                <div style={{ width: `150px` }}>{record.shortName}</div>
              ),
          },
          {
            title: "添加日期",
            dataIndex: "createTime",
            key: "createTime",
          },
        {
          title: "过期日期",
          dataIndex: "expirationTime",
          key: "expirationTime",
        },
        {
          title: "操作",
          dataIndex: "option",
          render: (text, record) => (
            <div>
                <a  onClick={() => onOpen(1, record)}> 编辑 </a>
                <span style={{ color: `#1DA57A`, cursor: `pointer` }} onClick={() => onDelete(record)}> 删除 </span>
            </div>
          ),
        },
    ];

    const [dataSource, setDataSource] = useState()
    const [pageNum, setPageNum] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalPage, setTotalPage] = useState()
    const [totalRecord, setTotalRecord] = useState()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const formRef = useRef()
    const [info, setInfo] = useState()
    const [companyList, setCompanyList] = useState([])
    const [nums, setNums] = useState(0)


    const _delete = async (obj) => {
        let values = {id: obj.id, recommend: -1,expirationTime: obj.expirationTime || obj.createTime}
        const res = await setRecommend({...values})
        if (res.code === 0) {
            message.success('操作成功!')
        } else {
            message.error('操作失败!')
        }
        _recommendCompany()
    }

    const onDelete = (obj) => {
        confirm({
            title: '温馨提示',
            icon: <ExclamationCircleOutlined />,
            content: '您确定做此操作?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
               _delete(obj)
            },
            onCancel() {},
          })
    }

    useEffect(() => {
        _recommendCompany()
    }, []);


    const _recommendCompany = async (start = 0, limit = 10) => {
        const { data, pageNum, pageSize, totalPage, totalRecord } = await recommendCompany({ start, limit })
        setDataSource(() => [...data])
        setPageNum(pageNum)
        setPageSize(pageSize)
        setTotalPage(totalPage)
        setTotalRecord(totalRecord)
    }

    const handleCancel = () => {
        formRef.current.resetFields()
        setIsModalVisible(false);
    }

    const onFinish = async (values) => {
        let time = moment(values.expirationTime).format('YYYY-MM-DD')
        values['expirationTime'] = time
        if (nums === 0) {
            let obj = JSON.parse(values.name)
            values['id'] = obj.id 
        } else {
            values['id'] = info.id
        }
        delete values['name']
        const res = await setRecommend({...values})
        if (res.code === 0) {
            message.success('操作成功!')
        } else {
            message.error('操作失败!')
        }
        _recommendCompany()
        handleCancel()
    }

    const highLightKeywords = (text, keyword) => {
        // 默认的标签，如果没有指定，使用span
        let reg = new RegExp("(" + keyword + ")", "g")
        let newStr = text.replace(reg, `<span style='color:#E6A23C;'>${keyword}</span>`)
        return newStr
    }

    const handleSearchCompany = debounce ((name) => {
        if (!name) return
        listLikenameByCompany({ limit: 10, start: 0, data: name }).then(res => {

                res.data.forEach((item) => {
                    item['newWords'] = highLightKeywords(item.shortName, name) + `(${highLightKeywords(item.name, name)})`
                })
                setCompanyList(() => [...res.data])
        })
    }, 1000)

    const onOpen = (num, obj) => {
        if (num === 0) {
            setCompanyList(() => [])
        }
        setNums(num)
        setIsModalVisible(true)
        let times = setTimeout(() => {
            clearTimeout(times)
            times = null 
            num === 0 ?  setInfo(() => {}) :  setInfo(() => {
                let o = obj
                formRef.current.setFieldsValue({ name: `${obj.shortName}(${obj.name})`,expirationTime :moment(obj.expirationTime || obj.createTime) })
                return {...o}
            })
           
        }, 800)
       /*   */
    }

    const handleChange = (current, pageSize) => {
        _recommendCompany((current - 1) * pageSize, pageSize);
      }

    return (
        <div className="activities-page">
               <Button className='btns' type="primary" onClick={() => onOpen(0)}>添加</Button>
               <Tabs defaultActiveKey="1">
                    <TabPane tab="首页公司栏位" key="1">
                        <Table
                        rowKey={(record) => record.id}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        />
                       {totalRecord > 10 ? (
                           <div className='pagination-box'>
                                <Pagination
                                    defaultCurrent={pageNum}
                                    total={totalRecord}
                                    onChange={handleChange}
                                    />
                           </div>
                        ) : null}
                    </TabPane>
                </Tabs>

                <Modal title={info && info.id ? '编辑首页公司栏位' : '添加首页公司栏位'} footer={null} visible={isModalVisible} onCancel={() => handleCancel()}>
                    <Form
                        ref = { formRef }
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="off"
                        >
        
                        <Form.Item
                            label="选择公司"
                            name="name"
                            rules={[{ required: true, message: '请输入搜索支持:牌匾名or公司名!' }]}
                        >
                            <Select
                                showSearch
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                disabled={info && info.id ? true : false}
                                placeholder='搜索支持:牌匾名or公司名'
                                onSearch={handleSearchCompany}>
                                {
                                    companyList.map((item, index) => {
                                        return <Select.Option key={index} value={JSON.stringify({ id: item.id, name: item.name,shortName: item.shortName })}>
                                            <span dangerouslySetInnerHTML={{__html: item.newWords}}></span>
                                         </Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="过期时间"
                            name="expirationTime"
                            rules={[{ required: true, message: '请输入过期时间!' }]}
                        >
                            <DatePicker locale={locale} format='YYYY-MM-DD' />
                        </Form.Item>
                        <Form.Item
                            label="顺序号"
                            name="recommend"
                            rules={[{ required: true, message: '请输入顺序号!' }]}
                        >
                            <Input />
                        </Form.Item>
                        
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button className='cancelBtn' onClick={() => handleCancel()}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Form.Item>
                        </Form>
                </Modal>

            </div>
    )
}

export default Index