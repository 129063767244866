import http from '../utils/axios'
import { post } from '../utils/up'


//

/**
 * 奖励结算延长
 * @param {*} data 
 */
export const delayReward = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/financial/delay/reward"
        http('post', url, {...data }).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 奖励结算延长申请列表
 * @param {*} data 
 */
export const delayList = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/financial/delay/reward/list"
        http('post', url, {...data }).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 设置推荐度
 * @param {*} params 
 */
export const setRecommend = (params) => {
    return new Promise((resolve, reject) => {
        let url = "/company/setRecommend"
        http('get', url, params).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 推荐公司
 * @param {*} data 
 */
export const recommendCompany = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/company/recommendCompany"
        http('post', url, {...data }).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 投递明细
 * @param {*} data 
 */
export const listTask = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/omsTask/listTask"
        http('post', url, {...data }).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 地推统计
 * @param {*} data 
 */
export const passedLogListByTime = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/passedLog/listByTime"
        http('post', url, {...data }).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 修改子账号信息
 * @param {*} data 
 */
export const updateAccount = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/account/updateAccount"
        http('post', url, {...data }).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 创建子账号信息
 * @param {*} data 
 */
export const createAccount = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/account/createAccount"
        http('post', url, {...data }).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 子账号列表
 * @param {*} start 
 * @param {*} limit 
 */
export const findAll = (start = 0, limit = 10) => {
    return new Promise((resolve, reject) => {
        let url = "/account/findAll"
        http('post', url, { start, limit }).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * C端提现完成
 * @param {*} data 
 */
export const cashOutFinish = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/cashOut/finish"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 退款完成
 * @param {*} data 
 */
export const refundFinish = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/refund/finish"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 退款失败
 * @param {*} data 
 */
export const refundFailed = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/refund/failed"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 积分记录
 * @param {*} data 
 */
export const financialCreditScore = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/financial/creditScore"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * C端提现
 * @param {*} data 
 */
export const cashOutList = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/cashOut/list"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * B端退款
 * @param {*} data 
 */
export const refundList = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/refund/list"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}


/**
 * 平台交易流水
 * @param {*} data 
 */
export const financialReward = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/financial/reward"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * C端列表
 * @param {*} data 
 */
export const financialCustomer = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/financial/customer"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * B端充值记录
 * @param {*} data 
 */
export const financialOrder = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/financial/order"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 企业列表 - 财务管理
 * @param {*} data 
 */
export const financialOverview = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/financial/overview"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 累计
 */
export const financialSum = () => {
    return new Promise((resolve, reject) => {
        let url = "/financial/sum"
        http('get', url, '').then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 上传64图片
 * @param {*}} data 
 */
export const uploadBase64 = (path) => {
    return new Promise((resolve, reject) => {
        let url = "/upload/base64"
            /* let formData = new FormData()
            formData.set('data', path) */
        let formData = { data: path }
        post(url, formData).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 退出
 * @param {*} data
 */
export const accountLogout = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/account/logout"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 推广列表
 * @param {*} data
 */
export const listByTime = (data) => {
    return new Promise((resolve, reject) => {
        let url = "/promote/listByTime"
        http('post', url, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

// 

/**
 * @description 创建和保存公司(创建不传id)
 * @param {*} data
 */
export const companyUpdate = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/company/update", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 创建和保存公司(创建不传id)
 * @param {*} data
 */
export const companySave = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/company/create", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 审核通过开通企业账号
 * @param {*} data
 */
export const accountVerified = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/enterprise/account/verified", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 开启账号
 * @param {*} id
 */
export const openAccount = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/enterprise/account/openAccount", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 关闭账号 - 禁用
 * @param {*} id
 */
export const closeAccount = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/enterprise/account/closeAccount", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 搜索企业用户账号
 * @param {*} limit 
 * @param {*} start 
 * @param {*} data
 */
export const accountListSearch = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/enterprise/account/listSearch", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 搜索正在申请的注册账号
 * @param {*} limit 
 * @param {*} start 
 * @param {*} data
 */
export const registerListSearch = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/enterprise/account/registerListSearch", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 获取正在申请的注册账号
 * @param {*} limit 
 * @param {*} start 
 */
export const registerList = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/enterprise/account/registerList", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 搜索公司
 * @param {*} data 
 */
export const listSearch = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/company/listSearch", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 查询企业信息 + 分页查询
 * @param {*} account 账号
 * @param {*} pwd 密码
 */
export const listLikenameByCompany = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/company/listLikeName", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 登陆
 * @param {*} account 账号
 * @param {*} pwd 密码
 */
export const login = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/account/login", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 获取公司详情
 * @param {*} 公司id
 */
export const companyDetail = (data) => {
    return new Promise((resolve, reject) => {
        http('post', "/company/detail", data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 省市区
 */
export const provinceMap = () => {
    return new Promise((resolve, reject) => {
        http('post', "/dict/province/map").then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 公司福利
 */
export const corporateWelfare = () => {
    return new Promise((resolve, reject) => {
        http('post', "/dict/corporateWelfare/list").then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 门店规模
 */
export const storeSize = () => {
    return new Promise((resolve, reject) => {
        http('post', "/dict/storeSize/list").then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}


/**
 *  @description 企业规模
 */
export const population = () => {
    return new Promise((resolve, reject) => {
        http('post', "/dict/population/list").then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}