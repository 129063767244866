import React, { Component } from 'react'
import moment from 'moment'
import { Table, Button, Pagination, DatePicker, message, 
    Tabs, Input, Select, Form, Modal, Radio } from 'antd'
import { 
    financialSum, 
    financialOverview, 
    financialOrder, 
    financialCustomer, 
    financialReward, 
    refundList,
    cashOutList,
    financialCreditScore,
    refundFinish,
    refundFailed,
    cashOutFinish,
    delayList,
    delayReward 
} from '../../api'
import { ExclamationCircleOutlined, } from '@ant-design/icons'
import 'moment/locale/zh-cn';

import locale from 'antd/es/date-picker/locale/zh_CN'
import "./index.less"

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select
let next = true //是否可以继续点击
let isReturnMoney = true //是否可以继续点击

const jiaoyiStatus = new Map([
    [-1, '邀约面试服务费'],
    [0, '内推奖金(推荐成功)'],
    [200, '内推奖金(面试通过)'],
    [400, '内推奖金(实际到岗)'],
])

const refundState = new Map([
    [0, '申请中'],
    [100, '处理中'],
    [200, '已完成'],
    [1000, '失败'],
    [2000, '已取消'],
]) 

const socreState = new Map([
    [0, '-'],
    [200, '邀约面试'],
    [400, '通过全部面试'],
    [500, '已报到上岗'],
    [10000, '淘汰']
])

const cStatus = new Map([
    [0, '申请'], //申请
    [100, '处理中'], 
    [200, '提现成功'], //完成
    [1000, '提现失败'] //失败
])

const columns = [
    {
      title: '企业名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '门店牌匾名',
      dataIndex: 'shortName',
      key: 'shortName',
    },
    {
      title: '当前可用余额',
      dataIndex: 'remainingSum',
      key: 'remainingSum',
      render: text => <span>{ Number(text).toFixed(2) }</span>
    },
    {
        title: '累计发放内推奖金',
        dataIndex: 'sumReward',
        key: 'sumReward',
        render: text => <span>{ Number(text).toFixed(2) }</span>
    },
    {
      title: '累计消费金额',
      key: 'sumServiceCommission',
      dataIndex: 'sumServiceCommission',
      render: text => <span>{ Number(text).toFixed(2) }</span>
    },
    {
      title: '信用分',
      key: 'creditScore',
      dataIndex: 'creditScore'
    },
  ];

  const columns2 = [
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '当前可提现金额',
      dataIndex: 'reward',
      key: 'reward',
      render: text => <span>{Number(text).toFixed(2)}</span>,
    }
  ];
  

  const columns3 = [
    {
      title: '流水号',
      dataIndex: 'sn',
      key: 'sn',
      render: text => <span>{text}</span>,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '支付时间',
      dataIndex: 'payTime',
      key: 'payTime',
    },
    {
        title: '门店',
        dataIndex: 'shortName',
        key: 'shortName',
    },
    {
      title: '用户',
      key: 'operatorName',
      dataIndex: 'operatorName',
    },
    {
      title: '金额',
      key: 'price',
      dataIndex: 'price'
    },
    {
        title: '支付方式',
        key: 'payType',
        dataIndex: 'payType',
        render: text => <span>{text === 1 ? '微信支付' : ''}</span>,
    },
    {
        title: '状态',
        key: 'orderState',
        dataIndex: 'orderState',
        render: text => <span>{text === 0 ? '创建' : text === 1000 ? '已支付': text === 10000 ? '申请退款' : text === 11000 ? '已退款' : text === 2000 ? '已取消' : ''}</span>,
    },
  ];

  const columns4 = [
    {
      title: '流水号',
      dataIndex: 'sn',
      key: 'sn',
      render: text => <span>{text}</span>,
    },
    {
      title: '交易时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '付款方门店',
      dataIndex: 'shortName',
      key: 'shortName',
    },
    {
        title: 'B端用户',
        dataIndex: 'operatorName',
        key: 'operatorName',
    },
    {
      title: '消费金额',
      key: 'money',
      dataIndex: 'money',
    },
    {
      title: '平台服务费率',
      key: 'serviceRate',
      dataIndex: 'serviceRate',
      render: (text,record) => <span>{record.serviceRate * 100}%</span>
    },
    {
        title: '平台服务费',
        key: 'platformCommission',
        dataIndex: 'platformCommission',
    },
    {
        title: '类别',
        key: 'orderState',
        dataIndex: 'orderState',//
        render: (text, record) => <span>{jiaoyiStatus.get(record.status)}</span>,
    },
    {
        title: '收款用户(C)',
        key: 'talentName',
        dataIndex: 'talentName'
      },
      {
        title: '收款金额',
        key: 'reward',
        dataIndex: 'reward',
        render: (text, record) => <span>{Number(text).toFixed(2)}</span>,
      },
      {
        title: '说明',
        key: 'op',
        dataIndex: 'op',
        render: (text, record) => <div>
            <div><span>职位: { record.jobName }</span></div>
            <div><span>求职者: { record.candidateName }</span></div>
        </div>
      },
  ];


  const columns7 = [
    {
      title: '门店牌匾名',
      dataIndex: 'shortName',
      key: 'shortName',
      render: text => <span>{text}</span>,
    },
    {
      title: '用户',
      dataIndex: 'operatorName',
      key: 'operatorName',
    },
    {
      title: '时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '类别',
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => <span>{ socreState.get(record.status) }</span>
    },
    {
        title: '分数',
        key: 'score',
        dataIndex: 'score',
        render: (text, record) => <span>+ {record.score}</span>
    },
    {
        title: '备注',
        key: 'op',
        dataIndex: 'op',
        render: (text, record) => <div>
            <div><span>职位: { record.jobName }</span></div>
            <div><span>求职者: { record.candidateName }</span></div>
        </div>,
    }
  ];


class Finance extends Component {

    formRef = React.createRef()
    formRef2 = React.createRef()
    formRef3 = React.createRef()
    formRef4 = React.createRef()
    formRef5 = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            tixianShow: false,
            isModalVisible: {
                type: 1,
                show: false,
                info: {}
            },
            defaultActiveKey: "1",
            commission: 0,//	内推抽成	number(float)	
            order: 0,//	充值总额	number(float)	
            remaining: 0,//	可用总余额	number(float)	
            reward: 0,//	内推奖金	number(float)	
            service: 0,//	面试服务
            companyInfo: { //企业列表
                data: [],
                pageNum: 1,
                pageSize: 10,
                totalRecord: 0,
            },
            delayDatas: [],
            cSearch: {
                begin: undefined,
                company: undefined,
                end: undefined,
                operator: undefined,
                phone: undefined,
                status: undefined,
                talent: undefined
            },
            columns5: [
                {
                  title: '流水号',
                  dataIndex: 'sn',
                  key: 'sn',
                  render: text => <span>{text}</span>,
                },
                {
                  title: '创建时间',
                  dataIndex: 'createTime',
                  key: 'createTime',
                },
                {
                  title: '退款时间',
                  dataIndex: 'refundTime',
                  key: 'refundTime',
                },
                {
                    title: '门店',
                    dataIndex: 'shortName',
                    key: 'shortName',
                },
                {
                  title: '用户',
                  key: 'operatorName',
                  dataIndex: 'operatorName',
                },
                {
                  title: '退款金额',
                  key: 'price',
                  dataIndex: 'price',
                  render: (text, record) => <span>{Number(!record.price ? 0 : record.price).toFixed(2)}</span>
                },
                {
                    title: '状态',
                    key: 'refundStatus',
                    dataIndex: 'refundStatus',
                    render: (text, record) => <span>{refundState.get(record.refundStatus)}</span>
                },
                {
                    title: '操作',
                    key: 'orderState',
                    dataIndex: 'orderState',
                    render: (text, record) => <div>
                        {
                            record.refundStatus === 0 || record.refundStatus === 200 ? <a  onClick={this.openIsModalVisible.bind(this, 1, record)}>处理</a> : <a onClick={this.openIsModalVisible.bind(this, 0, record)}>查看</a>
                        }
                    </div>,
                }
              ],
              columns6: [
                {
                  title: '流水号',
                  dataIndex: 'sn',
                  key: 'sn',
                  render: text => <span>{text}</span>,
                },
                {
                  title: '创建时间',
                  dataIndex: 'createTime',
                  key: 'createTime',
                },
                {
                  title: '提现完成时间',
                  dataIndex: 'finishTime',
                  key: 'finishTime',
                },
                {
                  title: '用户',
                  key: 'username',
                  dataIndex: 'username',
                },
                {
                  title: '提现金额',
                  key: 'cash',
                  dataIndex: 'cash',
                  render: (text, record) => <span>{Number(!record.cash ? 0 : record.cash).toFixed(2)}</span>
                },
                {
                    title: '状态',
                    key: 'status',
                    dataIndex: 'status',
                    render: (text, record) => <div>
                        <span>{cStatus.get(record.status)} {record.status === 1000 ? '：' + (!record.reason ? '无' : record.reason) : null}</span>
                    </div>
                },
                {
                    title: '操作',
                    key: 'op',
                    dataIndex: 'op',
                    render: (text, record) => <div>
                        { record.status === 0  ? <span className="cursors" onClick={this.onOpenTixian.bind(this, record.id)}>确认提现</span> : '-' }
                    </div>,
                }
              ],
              columns8: [
                {
                  title: '门店名',
                  dataIndex: 'shortName',
                  key: 'shortName',
                  render: text => <span>{text}</span>,
                },
                {
                  title: 'B端用户',
                  dataIndex: 'operatorName',
                  key: 'operatorName',
                },
                {
                  title: '职位名称',
                  dataIndex: 'jobName',
                  key: 'jobName',
                },
                {
                  title: '求职者',
                  key: 'status',
                  dataIndex: 'candidateInfo',
                  render: (text, record) => <span>{ !record.candidateInfo ? '' : record.candidateInfo.name }</span>
                },
                {
                    title: '自动结算时间',
                    key: 'scoexpirationTimere',
                    dataIndex: 'expirationTime',
                    render: (text, record) => <span>{record.expirationTime}</span>
                },
                {
                    title: '申请提交时间',
                    key: 'applyTime',
                    dataIndex: 'applyTime',
                    render: (text, record) => <span>{record.applyTime}</span>
                },
                {
                    title: '操作',
                    key: 'op',
                    dataIndex: 'op',
                    render: (text, record) => <div>
                        <span className='pass-btn' onClick={this.onDelays.bind(this, record.id, 1)}>通过</span> <span className='pass-btn' onClick={this.onDelays.bind(this,record.id, 2)}>拒绝</span>
                    </div>,
                }
              ],
              tuikuanInfo: undefined,
              cId: undefined
        }
    }

    componentDidMount() {
        this._financialSum()
        this._financialOverview() //企业列表
    }

    async _delayList (start = 0, limit = 10) {
        const res = await delayList({ start, limit})
        this.setState({
            companyInfo: { //B端退款
                data: res.data,
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalRecord: res.totalRecord
            }
        })
        //this.setState({delayDatas: res.data})
    }

    //积分记录 + 分页查询
    scorePaginaiton (current, pageSize){
        this._financialCreditScore(this.state.refundSeach, pageSize, (current - 1) * pageSize)
    }

    //积分记录 + 高级查询
    onSearchFinancialCreditScore (values) {
        if (values.begin) {
            let begin = moment(values.begin[0]).format('YYYY-MM-DD')
            let end = moment(values.begin[1]).format('YYYY-MM-DD')
            values['begin'] = begin
            values['end'] = end
        }
        if(!(/^1[123456789]\d{9}$/.test(values.operator))){ 
            values["operator"] = values.operator
            values["phone"] = undefined
        } else {
            values["phone"] = values.operator
            values["operator"] = undefined
        } 
        if (!values.status) {
            delete values.status
        }
        this.setState({cSearch: values})
        const { companyInfo } = this.state
        const num = companyInfo.pageNum === 0 ? 0 : (companyInfo.pageNum - 1)
        this._financialCreditScore(values, companyInfo.pageSize, num * companyInfo.pageSize)
    }

    //积分记录
    async _financialCreditScore(data = {}, limit = 10, start = 0) {
        const res = await financialCreditScore({ data, limit, start })
        this.setState({
            companyInfo: { //B端退款
                data: res.data,
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalRecord: res.totalRecord
            }
        })
    }

     // C端提现 + 分页查询
    cashListPaginaiton (current, pageSize) {
        this._cashOutList(this.state.cSearch, pageSize, (current - 1) * pageSize)
    }

    // C端提现 + 高级查询
    onSearchCashOutList (values) {
        if (values.begin) {
            let begin = moment(values.begin[0]).format('YYYY-MM-DD')
            let end = moment(values.begin[1]).format('YYYY-MM-DD')
            values['begin'] = begin
            values['end'] = end
        }
        if(!(/^1[123456789]\d{9}$/.test(values.operator))){ 
            values["talent"] = values.talent
            values["phone"] = undefined
        } else {
            values["phone"] = values.talent
            values["talent"] = undefined
        } 
        if (!values.status) {
            delete values.status
        }
        this.setState({cSearch: values})
        const { companyInfo } = this.state
        const num = companyInfo.pageNum === 0 ? 0 : (companyInfo.pageNum - 1)
        this._cashOutList(this.state.cSearch, companyInfo.pageSize, num * companyInfo.pageSize)
    }

    //C端提现
    async _cashOutList (data = {}, limit = 10, start = 0) {
        const res = await cashOutList({ data, limit, start })
        next = true //重新开放继续点击
        this.setState({
            companyInfo: { //C端提现
                data: res.data,
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalRecord: res.totalRecord
            }
        })
    }

    //B端退款 + 分页
    refundPaginaiton (current, pageSize) {
        console.log(current)
        this._refundList(this.state.cSearch, pageSize, (current - 1) * pageSize)
    }

    //B端退款 + 高级查询
    onSearchRefundList (values) {
        if (values.begin) {
            let begin = moment(values.begin[0]).format('YYYY-MM-DD')
            let end = moment(values.begin[1]).format('YYYY-MM-DD')
            values['begin'] = begin
            values['end'] = end
        }
        if(!(/^1[123456789]\d{9}$/.test(values.operator))){ 
            values["operator"] = values.operator
            values["phone"] = undefined
        } else {
            values["phone"] = values.operator
            values["operator"] = undefined
        } 
        if (!values.status) {
            delete values.status
        }
        this.setState({cSearch: values})
        const { companyInfo } = this.state
        const num = companyInfo.pageNum === 0 ? 0 : (companyInfo.pageNum - 1)
        this._refundList(values, companyInfo.pageSize, num * companyInfo.pageSize)
    }

    //B端退款
    async _refundList (data = {}, limit = 10, start = 0) {
        const res = await refundList({ data, limit, start })
        this.setState({
            companyInfo: { //B端退款
                data: res.data,
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalRecord: res.totalRecord
            }
        })
    }

    platformPaginaiton (current, pageSize) {
        console.log(current)
        this._financialReward(this.state.cSearch, pageSize, (current - 1) * pageSize)
    }

    //平台交易流水 + 高级查询
    onSerchFinancialReward (values) {
        
        if (values.begin) {
            let begin = moment(values.begin[0]).format('YYYY-MM-DD')
            let end = moment(values.begin[1]).format('YYYY-MM-DD')
            values['begin'] = begin
            values['end'] = end
        }
        if(!(/^1[123456789]\d{9}$/.test(values.operator))){ 
            values["operator"] = values.operator
            values["phone"] = undefined
        } else {
            values["phone"] = values.operator
            values["operator"] = undefined
        } 
        if (!values.status) {
            delete values.status
        }
        this.setState({cSearch: values})
        console.log(values)
        
        const { companyInfo } = this.state
        const num = companyInfo.pageNum === 0 ? 0 : (companyInfo.pageNum - 1)
        this._financialReward(values, companyInfo.pageSize, num * companyInfo.pageSize)

    }

    //平台交易流水
    async _financialReward (data = {}, limit = 10, start = 0) {
        const res = await financialReward({ data, limit, start })
        this.setState({
            companyInfo: { //企业列表
                data: res.data,
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalRecord: res.totalRecord
            }
        })
    }

    //C端高级查询
    onSearchFinancialCustomer(values) {
        console.log(values)
        const { companyInfo } = this.state
        this.setState({cSearch: values})
        this._financialCustomer(values, companyInfo.pageSize, (companyInfo.pageNum - 1) * companyInfo.pageSize)
    }

    //C端列表
    async _financialCustomer (data = {}, limit = 10, start = 0) {
        const res = await financialCustomer({ data, limit, start })
        this.setState({
            companyInfo: { //企业列表
                data: res.data,
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalRecord: res.totalRecord
            }
        })
    }

    // B端充值记录
    async _financialOrder (data = {}, limit = 10, start = 0) {
        const res = await financialOrder({ data, limit, start })
        this.setState({
            companyInfo: { //企业列表
                data: res.data,
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalRecord: res.totalRecord
            }
        })
    }

    onSearchFinancialOrder (values) {
        if (values.begin) {
            let begin = moment(values.begin[0]).format('YYYY-MM-DD')
            let end = moment(values.begin[1]).format('YYYY-MM-DD')
            values['begin'] = begin
            values['end'] = end
        }
        if(!(/^1[123456789]\d{9}$/.test(values.operator))){ 
            values["operator"] = values.operator
            values["phone"] = undefined
        } else {
            values["phone"] = values.talent
            values["operator"] = ""
        } 
        if (!values.status) {
            delete values.status
        }
        console.log(values,'sha...')
        this.setState({cSearch: values})

        const { companyInfo } = this.state
        const num = companyInfo.pageNum === 0 ? 0 : (companyInfo.pageNum - 1)
        this._financialOrder(values, companyInfo.pageSize, num * companyInfo.pageSize)
    }

    /**
     * B端充值流水 分页
     * @param {*} current 
     * @param {*} pageSize 
     */
    onfinancialOrderPag (current, pageSize) {
        const { cSearch } = this.state
        this._financialOrder(cSearch, pageSize, (current - 1) * pageSize)
    }

    //企业列表
    async _financialOverview (data = '', limit = 10, start = 0) {
        const res = await financialOverview({ data, limit, start })
        this.setState({
            companyInfo: { //企业列表
                data: res.data,
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalRecord: res.totalRecord
            }
        })
    }

    //累积额
    async _financialSum () {
        const res = await financialSum()
        const { commission, order, remaining, reward, service } = res.data
        this.setState({
            commission,//	内推抽成	number(float)	
            order,//	充值总额	number(float)	
            remaining,//	可用总余额	number(float)	
            reward,//	内推奖金	number(float)	
            service,//	面试服务
        })
        console.log(res, 'kankankann')
    }
    companySearch (values) {
        this.setState({cSearch: values})

        const { companyInfo } = this.state
        const num = companyInfo.pageNum === 0 ? 0 : (companyInfo.pageNum - 1)
        this._financialOverview(values.company, companyInfo.pageSize, num * companyInfo.pageSize)
    }

    handleChange (current, pageSize) {
        console.log(current)
        this._financialOverview(this.state.companySearch, pageSize, (current - 1) * pageSize)
    }

    callback (key) {
        this.setState({
            defaultActiveKey: key +'',
            companyInfo: { //企业列表
                data: [],
                pageNum: 1,
                pageSize: 10,
                totalRecord: 0,
            },
            cSearch: {
                begin: undefined,
                company: undefined,
                end: undefined,
                operator: undefined,
                phone: undefined,
                status: undefined,
                talent: undefined
            },
        })
        let methods = {
            1: () => this._financialOverview(),//企业列表
            2: () => this._financialCustomer(),//C端列表
            3: () => this._financialOrder(),//B端充值记录
            4: () => this._financialReward(),//平台交易流水
            5: () => this._refundList(),//B端退款
            6: () => this._cashOutList(),//C提现
            7: () => this._financialCreditScore(),//积分记录
            8: () => this._delayList(),//奖励结算延长申请列表
        }
        methods[key]()
        
    }

    onCloseIsModalVisible () {
        this.setState({ isModalVisible: { type: 1, show: false, info: {}} })
    }
    openIsModalVisible (type, item) {
        this.setState({
            isModalVisible: { type, show: true, info: item}
        })
    }

    // 退款完成
    async _refundFinish(id) {
        isReturnMoney = true
        const res = await refundFinish({data: id})
        res.code === 0 ? message.success('操作' + res.message) : message.error('操作' + res.message)
        this._refundList()//B端退款
        this.onCloseIsModalVisible()
    }

    //退款失败
    async _refundFailed(data) {
        isReturnMoney = true
        const res = await refundFailed(data)
        res.code === 0 ? message.success('操作' + res.message) : message.error('操作' + res.message)
        this._refundList()//B端退款
        this.onCloseIsModalVisible()
    }

    onFinishAmount (values) {
        const { isModalVisible } = this.state 
        if(!isReturnMoney)return 
        isReturnMoney = false //控制连续点击
        console.log(isModalVisible.info.id)
        if (values.type === 2) {
            this._refundFailed({
                data: {
                  id: isModalVisible.info.id,
                  name: values.reson }
              })
        } else {
            this._refundFinish(isModalVisible.info.id)
        }
    }

    onChangeAmountType (e) {
        const { value } = e.target
        this.setState({tuikuanInfo: value})
    }

    //打开确认提现弹框
    onOpenTixian (id) {
        this.setState({tixianShow: true, cId: id})
    }

    onCloseTixian () {
        next = true
        this.setState({tixianShow: false, cId: undefined})
    }

    async _cashOutFinish () {
        const { cId } = this.state
        if (!next) return //防止连续点击
        next = false
        const res = await cashOutFinish({data: cId})
        res.code === 0 ? message.success('操作' + res.message) : message.error('操作' + res.message)
        this._cashOutList()
        this.onCloseTixian()
    }

    async _delayReward (id, num) {
        console.log(num === 1 ? true : false)
        this.setState({delayDatas: []})
        const res = await delayReward({id, name: num === 1 ? true : false})
        res.code === 0 ? message.success('操作' + res.message) : message.error('操作' + res.message)
        this._delayList()
    }
   
    onDelays (id, num) {
        Modal.confirm({
            title: '温馨提示!',
            icon: <ExclamationCircleOutlined />,
            content: '确认做此操作吗？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                console.log(num)
                this._delayReward(id, num)
             },
          })
    }

    handleChange2 (current, pageSize) {
       this._delayList((current - 1) * pageSize, pageSize)
    }

    render() {
        const { commission, order, remaining, reward, service, 
            companyInfo, defaultActiveKey, isModalVisible, columns5, 
            columns6, tuikuanInfo, tixianShow } = this.state

        return (
            <div className='finance-page'>
               <div className="flex-box">
                   <div className="item-box">
                       <div>累计B端充值总额</div>
                       <div className="big-money">{Number(order).toFixed(2)}</div>
                   </div>
                   <div className="item-box">
                       <div>当前B端可用总余额</div>
                       <div className="big-money">{Number(remaining).toFixed(2)}</div>
                   </div>
                   <div className="item-box">
                       <div>累计发放内推奖金(含抽成)</div>
                       <div className="big-money">{Number(reward).toFixed(2)}</div>
                   </div>
                   <div className="item-box">
                       <div>累计收取内推抽成</div>
                       <div className="big-money">{Number(commission).toFixed(2)}</div>
                   </div>
                   <div className="item-box">
                       <div>累计收取面试服务费</div>
                       <div className="big-money">{Number(service).toFixed(2)}</div>
                   </div>
               </div>

               <div>
               <Tabs defaultActiveKey={defaultActiveKey} onChange={this.callback.bind(this)}>
                    <TabPane tab="企业列表" key="1">
                        <Form 
                            onFinish={this.companySearch.bind(this)}
                        >
                            <div className="search-flex">
                                <div className="flex-item">
                                    <Form.Item label="门店牌匾名" name="company">
                                        <Input placeholder="门店牌匾名搜索" />
                                    </Form.Item>
                                </div>
                                
                                <div className="flex-item">
                                    <Form.Item>
                                    <Button type="primary" htmlType="submit">搜索</Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                        <Table pagination={false} rowKey={(record) => record.id} columns={columns} dataSource={companyInfo.data} />
                        {
                            companyInfo.totalRecord > 10 ? (
                                <div className="pagination-box">
                                    <Pagination defaultCurrent={companyInfo.pageNum} 
                                    total={companyInfo.totalRecord} 
                                    onChange={this.handleChange.bind(this)} />
                                </div>
                            ) : null
                        }
                    </TabPane>
                    <TabPane tab="C端列表" key="2">
                    <Form 
                            onFinish={this.onSearchFinancialCustomer.bind(this)}
                        >
                            <div className="search-flex">
                                <div className="flex-item">
                                    <Form.Item label="手机号" name="phone">
                                        <Input placeholder="手机号搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                    <Form.Item label="姓名" name="talent">
                                        <Input placeholder="姓名搜索" />
                                    </Form.Item>
                                </div>
                                
                                <div className="flex-item">
                                    <Form.Item>
                                    <Button type="primary" htmlType="submit">搜索</Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                        <Table pagination={false} rowKey={(record) => record.id} columns={columns2} dataSource={companyInfo.data} />
                        {
                            companyInfo.totalRecord > 10 ? (
                                <div className="pagination-box">
                                    <Pagination defaultCurrent={companyInfo.pageNum} 
                                    total={companyInfo.totalRecord} 
                                    onChange={this.handleChange.bind(this)} />
                                </div>
                            ) : null
                        }
                    </TabPane>
                    <TabPane tab="B端充值流水" key="3">
                        <Form 
                            ref={this.formRef}
                            initialValues={{status: ""}}
                            onFinish={this.onSearchFinancialOrder.bind(this)}
                        >
                            <div className="search-flex">
                                <div className="flex-item">
                                    <Form.Item label="门店" name="company">
                                        <Input placeholder="门店牌匾名搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                    <Form.Item label="用户" name="operator">
                                        <Input placeholder="用户名或手机号搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                    <Form.Item label="创建时间" name="begin">
                                        <RangePicker locale={locale} />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                    <Form.Item label="状态" name="status">
                                        <Select style={{ width: 120 }}>
                                            <Option value="">全部</Option>
                                            <Option value="0">创建</Option>
                                            <Option value="1000">已支付</Option>
                                            <Option value="2000">已取消</Option>
                                            <Option value="10000">申请退款</Option>
                                            <Option value="11000">已退款</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">搜索</Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                        
                        <Table pagination={false} rowKey={(record) => record.id} columns={columns3} dataSource={companyInfo.data} />
                        {
                            companyInfo.totalRecord > 10 ? (
                                <div className="pagination-box">
                                    <Pagination defaultCurrent={companyInfo.pageNum} 
                                    total={companyInfo.totalRecord} 
                                    onChange={this.onfinancialOrderPag.bind(this)} />
                                </div>
                            ) : null
                        }
                    </TabPane>
                    <TabPane tab="平台交易流水" key="4">
                    <Form 
                        ref={this.formRef2}
                        initialValues={{status: ""}}
                        onFinish={this.onSerchFinancialReward.bind(this)}
                        >
                            <div className="search-flex">
                                <div className="flex-item">
                                    <Form.Item label="付款门店" name="company">
                                        <Input placeholder="门店牌匾名搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                    <Form.Item label="B端用户" name="operator">
                                        <Input placeholder="用户名或手机号搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                    <Form.Item label="收款用户" name="talent">
                                        <Input placeholder="用户名或手机号搜索" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="search-flex">
                                <div className="flex-item">
                                        <Form.Item label="交易时间" name="begin">
                                            <RangePicker locale={locale} />
                                        </Form.Item>
                                    </div>
                                    <div className="flex-item">
                                        <Form.Item label="状态" name="status">
                                            <Select style={{ width: 120 }}>
                                                <Option value="">全部</Option>
                                                <Option value="0">内推奖金(推荐成功)</Option>
                                                <Option value="200">内推奖金(面试通过)</Option>
                                                <Option value="400">内推奖金(实际到岗)</Option>
                                                <Option value="-1">邀约面试服务费</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="flex-item">
                                        <Form.Item>
                                        <Button type="primary" htmlType="submit">搜索</Button>
                                        </Form.Item>
                                    </div>
                            </div>
                        </Form>
                        <Table pagination={false} rowKey={(record) => record.id} columns={columns4} dataSource={companyInfo.data} />
                        {
                            companyInfo.totalRecord > 10 ? (
                                <div className="pagination-box">
                                    <Pagination defaultCurrent={companyInfo.pageNum} 
                                    total={companyInfo.totalRecord} 
                                    onChange={this.platformPaginaiton.bind(this)} />
                                </div>
                            ) : null
                        }
                    </TabPane>
                    <TabPane tab="B端退款" key="5">
                    <Form 
                        ref={this.formRef3}
                        initialValues={{status: ""}}
                        onFinish={this.onSearchRefundList.bind(this)}
                        >
                            <div className="search-flex">
                                <div className="flex-item">
                                    <Form.Item label="门店" name="company">
                                        <Input placeholder="门店牌匾名搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                    <Form.Item label="用户" name="operator">
                                        <Input placeholder="用户名或手机号搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                        <Form.Item label="创建时间" name="begin">
                                            <RangePicker locale={locale} />
                                        </Form.Item>
                                    </div>
                                    <div className="flex-item">
                                        <Form.Item label="状态" name="status">
                                            <Select style={{ width: 120 }}>
                                                <Option value="">全部</Option>
                                                <Option value="100">退款中</Option>
                                                <Option value="1000">退款失败</Option>
                                                <Option value="200">已退款</Option>
                                                <Option value="2000">已撤销退款</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="flex-item">
                                        <Form.Item>
                                        <Button type="primary" htmlType="submit">搜索</Button>
                                        </Form.Item>
                                    </div>
                            </div>
                        </Form>
                        <Table pagination={false} rowKey={(record) => record.id} columns={columns5} dataSource={companyInfo.data} />
                        {
                            companyInfo.totalRecord > 10 ? (
                                <div className="pagination-box">
                                    <Pagination defaultCurrent={companyInfo.pageNum} 
                                    total={companyInfo.totalRecord} 
                                    onChange={this.refundPaginaiton.bind(this)} />
                                </div>
                            ) : null
                        }
                    </TabPane>
                    <TabPane tab="C端提现" key="6">
                        <Form 
                            ref={this.formRef4}
                            initialValues={{status: ""}}
                            onFinish={this.onSearchCashOutList.bind(this)}
                        >
                            <div className="search-flex">
                                <div className="flex-item">
                                    <Form.Item label="用户" name="talent">
                                        <Input placeholder="用户名或手机号搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                        <Form.Item label="创建时间" name="begin">
                                            <RangePicker locale={locale} />
                                        </Form.Item>
                                    </div>
                                    <div className="flex-item">
                                        <Form.Item label="状态" name="status">
                                            <Select style={{ width: 120 }}>
                                                <Option value="">全部</Option>
                                                <Option value="100">提现中</Option>
                                                <Option value="1000">提现失败</Option>
                                                <Option value="200">已提现</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="flex-item">
                                        <Form.Item>
                                        <Button type="primary" htmlType="submit">搜索</Button>
                                        </Form.Item>
                                    </div>
                            </div>
                        </Form>
                        <Table pagination={false} rowKey={(record) => record.id} columns={columns6} dataSource={companyInfo.data} />
                        {
                            companyInfo.totalRecord > 10 ? (
                                <div className="pagination-box">
                                    <Pagination defaultCurrent={companyInfo.pageNum} 
                                    total={companyInfo.totalRecord} 
                                    onChange={this.cashListPaginaiton.bind(this)} />
                                </div>
                            ) : null
                        }
                    </TabPane>
                    <TabPane tab="信用分记录" key="7">
                    <Form 
                         ref={this.formRef5}
                         initialValues={{status: ""}}
                         onFinish={this.onSearchFinancialCreditScore.bind(this)}
                        >
                            <div className="search-flex">
                                <div className="flex-item">
                                    <Form.Item label="门店" name="company">
                                        <Input placeholder="门店牌匾名搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                    <Form.Item label="用户" name="operator">
                                        <Input placeholder="用户名或手机号搜索" />
                                    </Form.Item>
                                </div>
                                <div className="flex-item">
                                        <Form.Item label="时间" name="begin">
                                            <RangePicker locale={locale} />
                                        </Form.Item>
                                    </div>
                                    <div className="flex-item">
                                        <Form.Item label="状态" name="status">
                                            <Select style={{ width: 120 }}>
                                                <Option value="">全部</Option>
                                                <Option value="0">邀约面试</Option>
                                                <Option value="200">通过全部面试</Option>
                                                <Option value="400">已报道上岗</Option>
                                                <Option value="10000">淘汰简历</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="flex-item">
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">搜索</Button>
                                        </Form.Item>
                                    </div>
                            </div>
                        </Form>
                        <Table pagination={false} rowKey={(record) => record.id} columns={columns7} dataSource={companyInfo.data} />
                        {
                            companyInfo.totalRecord > 10 ? (
                                <div className="pagination-box">
                                    <Pagination defaultCurrent={companyInfo.pageNum} 
                                    total={companyInfo.totalRecord} 
                                    onChange={this.scorePaginaiton.bind(this)} />
                                </div>
                            ) : null
                        }
                    </TabPane>
                    <TabPane tab="延长结算申请" key="8">
                        <Table pagination={false} rowKey={(record) => record.id} columns={this.state.columns8} dataSource={companyInfo.data} />
                        {
                            companyInfo.totalRecord > 10 ? (
                                <div className="pagination-box">
                                    <Pagination defaultCurrent={companyInfo.pageNum} 
                                    total={companyInfo.totalRecord} 
                                    onChange={this.handleChange2.bind(this)} />
                                </div>
                            ) : null
                        }
                    </TabPane>
                </Tabs>
               </div>

               <Modal title="退款详情" visible={isModalVisible.show} footer={null} onCancel={this.onCloseIsModalVisible.bind(this)}>
                    <div>
                        <div className="tuikuan-header-box">
                            <div className="item-box">
                                <span className="label-box">流水号：</span><span>{isModalVisible.info.sn}</span>
                            </div> 
                            <div className="item-box">
                                <span className="label-box">创建时间：</span><span>{isModalVisible.info.createTime}</span>
                            </div>
                            <div className="item-box">
                                <span className="label-box">门店：</span><span>{isModalVisible.info.shortName}</span>
                            </div>
                            <div className="item-box">
                                <span className="label-box">用户：</span><span>{isModalVisible.info.operatorName}</span>
                            </div>
                            <div className="item-box">
                                <span className="label-box">退款金额：</span><span>{isModalVisible.info.price}</span>
                            </div>
                            <div className="item-box">
                                <span className="label-box">退款时间：</span><span>{isModalVisible.info.refundTime}</span>
                            </div>        
                        </div>  
                        <div className="tuikuan-header-box">
                            <div>退款账户</div>
                            <div className="item-box">
                                <span className="label-box">开户银行：</span><span>{isModalVisible.info.bank}</span>
                            </div> 
                            <div className="item-box">
                                <span className="label-box">支行名称：</span><span>{isModalVisible.info.subbranch}</span>
                            </div> 
                            <div className="item-box">
                                <span className="label-box">银行账号：</span><span>{isModalVisible.info.bankAccount}</span>
                            </div> 
                            <div className="item-box">
                                <span className="label-box">开户人姓名：</span><span>{isModalVisible.info.ownerName}</span>
                            </div> 
                            <div className="item-box">
                                <span className="label-box">手机号码：</span><span>{isModalVisible.info.ownerPhone}</span>
                            </div> 
                            <div className="item-box">
                                <span className="label-box">留言：</span><span>{isModalVisible.info.remark}</span>
                            </div> 
                        </div> 

                        {
                            isModalVisible.type === 1 ? (
                                <div className="tuikuan-header-box">
                                    <div>退款结果</div>
                                    <Form  onFinish={this.onFinishAmount.bind(this)}>
                                        <Form.Item name="type">
                                            <Radio.Group onChange={this.onChangeAmountType.bind(this)}>
                                                <Radio value={1}>已退款</Radio>
                                                <Radio value={2}>退款失败</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        {
                                            tuikuanInfo === 2 ? (
                                                <Form.Item name="reson">
                                                    <Input placeholder="请填写退款失败原因" />
                                                </Form.Item>
                                            ) : null
                                        }
                                        
                                        <Form.Item>
                                            <Button className="mr30" htmlType="button" onClick={this.onCloseIsModalVisible.bind(this)}> 取消 </Button>
                                            <Button type="primary" htmlType="submit">确认</Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            ) : null
                        }
                        
                    </div>    
               </Modal>
               <Modal title="确认提现" visible={tixianShow} footer={null} onCancel={this.onCloseTixian.bind(this)}>  
                    <div>
                        <div>
                            <div>确认发起提现？</div>
                            <div>确认前请确保：</div>
                            <div>1.用户的奖金来源合理;</div>
                            <div>2.微信支付余额足够;</div>
                        </div>
                        <div>
                            <div>确认后：</div>
                            <div>系统将调用微信接口，将微信支付内的余额</div>
                            <div>发放至用户微信号的零钱账户中。</div>
                        </div> 
                        <div className="tixian-footer">
                            <Button className="cancel-btn" size="mini" onClick={this.onCloseTixian.bind(this)}>取消</Button>
                            <Button size="mini" type="primary" onClick={this._cashOutFinish.bind(this)}>提交</Button>
                        </div>
                        <div className="tixian-tips">提交后不可撤销，请谨慎操作！！</div>  
                    </div> 
                     
               </Modal>
            </div>
        )
    }
}

export default Finance