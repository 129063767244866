import React, { Component } from "react";
import { Table, Input, Button, Pagination, Upload, message } from "antd";
import { listSearch, companyUpdate } from "../../api";

const { Search } = Input;

let config = { path: "companyLogo" };
let OMS = "";
if (process.env.NODE_ENV === "development") {
    OMS = "/api";
} else {
    OMS = "https://api.fanwoon.com/oms";
}

let uploadPath = `${OMS}/upload/uploadImage`;

class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            pageNum: 1,
            pageSize: 10,
            totalPage: 1,
            totalRecord: 10,
            nums: 1,
            searchName: "",
            pic: "",
            companyItem: {},
            columns: [{
                    title: "公司名称",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "logo",
                    dataIndex: "logo",
                    render: (text, record) => ( 
                        <div>
                            <img className = "logos"
                            src = { record.logo }
                            alt = { record.shortName }
                            /> 
                            <Upload name = "file"
                            headers = { config }
                            action = { uploadPath }
                            showUploadList = { false }
                            beforeUpload = { this.beforeUpload }
                            onChange = { this.handleChanges }
                            withCredentials = { true } >
                                <span className = "upload-btn"
                                onClick = { this.onUps.bind(this, record) } >
                                上传 </span> 
                            </Upload> 
                        </div>
                    ),
                },
                {
                    title: "线下门店牌匾名",
                    dataIndex: "shortName",
                    key: "shortName",
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                },
                {
                    title: "操作",
                    dataIndex: "option",
                    render: (text, record) => ( <a href = { `/editeCompany?id=${record.id}` } > 编辑 </a>
                    ),
                },
            ],
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSeletected = this.handleChangeSeletected.bind(this);
    }

    beforeUpload(file) {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    }

    onUps(record) {
        this.setState({ companyItem: record });
    }

    async upLogo(id, logo) {
        let data = { id, logo };
        await companyUpdate(data);
    }

    handleChanges = (infos) => {
        if (infos.file.status === "uploading") {
            this.setState({ loading: true });
            return;
        }
        if (infos.file.status === "done") {
            // Get this url from response in real world.
            let res = infos.file.response.data;
            console.log(res, "上传了。。。");
            /* let { info } = this.state
                            info['logo'] = res */
            let { companyItem } = this.state;
            this.upLogo(companyItem.id, res);
            this.state.dataSource.forEach((item) => {
                if (item.id === companyItem.id) {
                    item.logo = res; //'https://img.tueneitui.com/companyLogo/7ef8a234-4f7c-43df-bdfa-9803042586f5.png'
                }
            });

            //this.getCompanyAll(this.state.pageSize, this.state.nums)
            this.setState({
                loading: false,
                // pic: res
            });
        }
    };

    handleGoEditeCompany = () => {
        this.props.history.push({ pathname: "/editeCompany" });
    };

    getCompanyAll = async(limit = 10, start = 0, name = "") => {
        let params = { limit, start, data: name };
        let { data, pageNum, pageSize, totalPage, totalRecord } = await listSearch(
            params
        );
        this.setState({
            dataSource: data,
            pageNum: pageNum,
            pageSize: pageSize,
            totalPage: totalPage,
            totalRecord: totalRecord,
        });
        console.log(data);
    };

    handleSearchName = async(data) => {
        this.setState({ searchName: data });
        this.getCompanyAll(10, 0, data);
    };

    componentDidMount() {
        this.getCompanyAll();
    }

    handleChange(current, pageSize) {
        let { searchName } = this.state;
        console.log(current);
        this.setState({ nums: (current - 1) * pageSize });
        this.getCompanyAll(pageSize, (current - 1) * pageSize, searchName);
    }

    handleChangeSeletected(current, pageSize) {
        console.log(current, pageSize, "-==----");
        let { searchName } = this.state;
        console.log(current);
        this.getCompanyAll(pageSize, (current - 1) * pageSize, searchName);
    }

    render() {
        const { dataSource, totalRecord, pageNum, columns } = this.state;
        return ( 
            <div className = "company-page">
            <div className = "company-tool-bar">
                <div>
                    <Search className = "search-box"
                    placeholder = "线下门店牌匾名"
                    enterButton = "搜索"
                    size = "large"
                    onSearch = {
                        (value) => this.handleSearchName(value)
                    }
                    /> 
                </div> 
            <div>
            <Button onClick = { this.handleGoEditeCompany }
            type = "primary" >新建公司 </Button> </div> 
            </div> 
            <Table rowKey = {
                (record) => record.id
            }
            dataSource = { dataSource }
            columns = { columns }
            pagination = { false }
            /> 
            <div className = "pagination-box" >
            {
                totalRecord > 10 ? ( <
                    Pagination defaultCurrent = { pageNum }
                    total = { totalRecord }
                    onChange = { this.handleChange }
                    />
                ) : null
            } 
            </div> 
          </div>
        );
    }
}

export default Company;