import React, { useState, useEffect, useRef } from 'react'
import {
    Button, Table, Input, Modal,Form,Radio, Pagination,message
} from 'antd'

import {
    findAll,
    createAccount,
    updateAccount
} from '../../api'

import './index.less'



const Index = () => {

    useEffect(() => {
        onFindAll()
    },[])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [data, setData] = useState()
    const [info, setInfo] = useState()
    const formRef = useRef()

    const showModal = (num) => {
        if (num === 0) {
            setInfo()
        }
        setIsModalVisible(true);
        let timers = setTimeout(() => {
            clearTimeout(timers)
            formRef.current.setFieldsValue({ status: 0 })
        }, 50)
    };

    const handleCancel = () => {
        formRef.current.resetFields()
        setIsModalVisible(false);
    }

    const onFindAll = async (start = 0, limit = 10) => {
        const res = await findAll(start, limit)
        console.log(res, '子账号列表')
        setData(() => [...res.data])
    }

    const [columns] = useState([
        { title: '账号', dataIndex: 'account', key: 'account' },
        { title: '姓名', dataIndex: 'name', key: 'name' },
        { 
            title: '状态', 
            dataIndex: 'status', 
            key: 'status',
            render: (text, record) => (
                <span>{record.status === 0 ? '停用' : '正常'}</span>
            )
        },
        { title: '创建时间', dataIndex: 'createTime', key: 'createTime' },
        { title: '最新修改时间', dataIndex: 'updateTime', key: 'updateTime' },
        {
            title: '操作',
            dataIndex: 'option',
            render: (text, record) => (
                <span className='cursors default-color' onClick={() => onEditeInfo(record)}>编辑</span>
            )
        }
    ])
   

    const _createAccount = async (data) => {
        let res = ''
        if (!info || !info.id) {
            res = await createAccount(data)
        } else {
            data['account'] = info.account
            data['id'] = info.id
            res = await updateAccount(data)
        }
        formRef.current.resetFields()
        if (res.code === 0) {
            let str = info && info.id ? "编辑子账号成功!" : "创建子账号成功!";
            message.success(str);
            onFindAll()
            return;
        } else {
            message.error(info && info.id ? "编辑子账号失败!" : "新增子账号失败!");
        }
    }

    const checkAccount = (rule, value) => {
        const regNumber =  /^[0-9]{8,30}$/
        if (!regNumber.test(value)) {
            return Promise.reject("不小于8位，建议手机号!");
        }
        return Promise.resolve();
    }

    const checkePwd = (rule, value) => {
        const regNumber =  /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{8,30}$/
        if (info && info.id && !value) {
            return Promise.resolve();
        }
        if (!regNumber.test(value)) {
            return Promise.reject("8位以上，大小写字母+数字，不改则无需填写");
        }
        return Promise.resolve();
    }

    const onFinish = (values) => {
        _createAccount(values)
        console.log('Success:', values);
        setIsModalVisible(false);
        //handleCancel()
    }
    
    const onEditeInfo = (item) => {
        setInfo(item)
        showModal(1)
        let timers = setTimeout(() => {
            clearTimeout(timers)
            formRef.current.setFieldsValue({ name: item.name })
            formRef.current.setFieldsValue({ empNo: item.empNo })
            formRef.current.setFieldsValue({ status: item.status })
        }, 50)
    }

    return (
        <div>
            <div className='headerBox'><Button type='primary' onClick={() => showModal(0)}>新增子账号</Button></div>
            <div>
            <Table
                rowKey={(record) => record.id}
                dataSource={data}
                columns={columns}
                pagination={false} />

                <Modal title={info && info.id ? '编辑子账号' : '新增子账号'} footer={null} visible={isModalVisible} onCancel={() => handleCancel()}>
                    <Form
                        ref = { formRef }
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="off"
                        >
                        {
                            info && info.id ? (
                                <div>
                                     <Form.Item
                                        label="账号"
                                    >
                                        {info.account}
                                    </Form.Item>
                                </div>
                            ) : (
                                <Form.Item
                                    label="账号"
                                    name="account"
                                    placeholder="不小于8位，建议手机号"
                                    rules={[{ required: true, validator: checkAccount }]}
                                >
                                    <Input />
                                </Form.Item>
                            )
                        }
                        
                        <Form.Item
                            label="姓名"
                            name="name"
                            rules={[{ required: true, message: '请输入姓名!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="工号"
                            name="empNo"
                            rules={[{ required: true, message: '请输入工号!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={info && info.id ? '重置密码' : '密码'}
                            name="password"
                            placeholder="8位以上，大小写字母+数字"
                            rules={[{ required: info && info.id ? false : true, validator: checkePwd }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        {
                            info && info.id ? (
                                <Form.Item label="状态" name='status'>
                                    <Radio.Group>
                                        <Radio value={1}>正常</Radio>
                                        <Radio value={0}>停用</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            ) : null
                        }
                        
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button className='cancelBtn' onClick={() => handleCancel()}>取消</Button>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Form.Item>
                        </Form>
                </Modal>    
            </div>
        </div>
    )
}

export default Index