import React, { Component } from 'react'
import { Form, Input, Button, message } from 'antd'
import { login } from '../../api'

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {}
        //fanwoonRoot@test
    }

    onFinish = async (params) => {
      try {
        let { data } = await login(params)
        if (!data) {
          message.error('账号或密码有误')
          return
        } else {
          message.success('登陆成功!')
          sessionStorage.setItem('U', JSON.stringify(data))
          setTimeout(() => window.location.href = '/company', 2000)
        }
      } catch (error) {
          console.log(error)
      }
    }
    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    render() {
        return (
            <div className='login-page'>
                <div className='login-bar-box'>
                    <div className='login-form-box'>
                        <div className='oms-box'>OMS登录</div>
                        <div className='oms-form-sub-box'>
                            <Form
                                initialValues={{ remember: true }}
                                onFinish={this.onFinish} >
                                <Form.Item 
                                    label='用户名' 
                                    name='account' 
                                    rules={[{ required: true, message: '请输入用户名!' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item 
                                    label='&nbsp;&nbsp;&nbsp;&nbsp;密码' 
                                    name='password' 
                                    value='!@#123QWEasd'
                                    rules={[{ required: true, message: '请输入密码!' }]}>
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item>
                                    <Button className='login-form-btn' type="primary" htmlType="submit">
                                        登录
                                     </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;