import React, { Component } from 'react'
import {
    Tabs, Table, Input, Modal, Pagination,message
} from 'antd'
import {
    registerListSearch, accountListSearch, closeAccount, openAccount
} from '../../api'

import AccountForm from '../../components/AccountForm' 

const { TabPane } = Tabs
const { Search } = Input
const { confirm } = Modal

class Company extends Component {

    constructor(props) {
        super(props)
        this.state = {
            registerListArray: [],
            accountListArray: [],

            register: {
                pageNum: 1,
                pageSize: 10,
                totalPage: 0,
                totalRecord: 0
            },
            register2: {
                pageNum: 1,
                pageSize: 10,
                totalPage: 0,
                totalRecord: 0
            },
            visible: false,
            searchName: '',
            modalForm: 0, //0默认开通， 1 bian
            account: {},
            columns: [
                { title: '申请时间', dataIndex: 'createTime', key: 'createTime' },
                { title: '姓名', dataIndex: 'name', key: 'name' },
                {
                    title: '性别',
                    dataIndex: 'gender',
                    render: (text, record) => <span>{record.gender === 1 ? '男' : '女'}</span>
                },
                { title: '身份', dataIndex: 'jobGrade', key: 'jobGrade' },
                { title: '公司', dataIndex: 'companyName', key: 'companyName' },
                { title: '线下门店牌匾名', dataIndex: 'shortName', key: 'shortName' },
                { title: '手机号', dataIndex: 'phone', key: 'phone' },
                { title: '推荐人', dataIndex: 'referrer', key: 'referrer' },
                {
                    title: '操作',
                    dataIndex: 'option',
                    render: (text, record) => (
                        <span className='cursors default-color' onClick={this.openExamine.bind(this, record, 0)}>开通</span>
                    )
                }

            ],
            columns2: [
                { title: '姓名', dataIndex: 'name', key: 'name' },
                {
                    title: '性别',
                    dataIndex: 'gender',
                    render: (text, record) => <span>{record.gender === 1 ? '男' : '女'}</span>
                },
                { title: '身份', dataIndex: 'jobGrade', key: 'jobGrade' },
                { title: '所属公司', dataIndex: 'companyName', key: 'companyName' },
                { title: '线下门店牌匾名', dataIndex: 'shortName', key: 'shortName' },
                {
                    title: '角色',
                    dataIndex: 'rose',
                    render: (text, record) => <span>{record.system ? '管理员' : '普通用户'}</span>
                },
                { title: '手机号', dataIndex: 'phone', key: 'phone' },
                { title: '推荐人', dataIndex: 'referrer', key: 'referrer' },
                { title: '开通时间', dataIndex: 'createTime', key: 'createTime' },
                {
                    title: '操作',
                    dataIndex: 'option',
                    render: (text, record) => {
                        return (
                            <div>
                                { this.showOptionsBtn.bind(this, record)}
                                {
                                    record.status === 0 ? null : 
                                    <span className='cursors default-color' onClick={this.openExamine.bind(this, record, 1)}>编辑</span>
                                }

                            </div>
                        )
                    }
                }
            ]
        }
        this.handleOpenEdite = this.handleOpenEdite.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
    }

    getChildrenMsg() {
        this.setState({visible: false})
    }

    openExamine(v, num = 0) {
        this.setState({ visible: true, modalForm: num, account: v, code: 9999 })
    }

    componentDidMount() {
        this.handleRegisterList()
        this.handleAccountListSearch()
    }

     showOptionsBtn = (record) => {
        let local = sessionStorage.getItem('U')
        local = JSON.parse(local)
        if (local.system) {
            return (
                <div>
                   {
                        record.status === 0 ?
                            <span
                                className='m-r-16 cursors default-color'
                                onClick={this.handleOpenAccount.bind(this, record.id)}>启用</span> :
                            <span
                                className='m-r-16 cursors default-color'
                                onClick={this.showConfirm.bind(this, record.id)}>禁用</span>
                    }
                </div>
            )
        } else {
            return <span
            className='m-r-16 cursors default-color'
            onClick={this.handleOpenAccount.bind(this, record.id)}>启用</span> 
        }
    }

    async handleAccountListSearch(limit = 10, start = 0, data = '') {
        let res = await accountListSearch({ limit, start, data })
        this.setState({
            accountListArray: res.data,
            register2: {
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalPage: res.totalPage,
                totalRecord: res.totalRecord
            },
            searchName2: ''
        })
    }

    async handleRegisterList(limit = 10, start = 0, data = '') {
        let res = await registerListSearch({ limit, start, data })
        this.setState({
            registerListArray: res.data,
            register: {
                pageNum: res.pageNum,
                pageSize: res.pageSize,
                totalPage: res.totalPage,
                totalRecord: res.totalRecord
            },
            searchName: ''
        })
    }

    handleOpenEdite = e => {
        this.setState({ visible: true })
    }

    handleChange(current, pageSize) {
        let { searchName } = this.state
        this.handleRegisterList(pageSize, (current - 1) * pageSize, searchName)
    }
    handleChange2(current, pageSize) {
        let { searchName2 } = this.state
        this.handleAccountListSearch(pageSize, (current - 1) * pageSize, searchName2)
    }

    handleSearchName = (data) => {
        this.setState({ searchName: data })
        this.handleRegisterList(10, 0, data)
    }
    handleSearchName2 = (data) => {
        this.setState({ searchName2: data })
        this.handleAccountListSearch(10, 0, data)
    }

    handleSubmit() {
        console.log(this.state.modalForm)
    }

    showConfirm(id) {
        let data = { data: id }
        let _self = this
        confirm({
            title: '禁用后，该账号将无法登陆。',
            content: '但该公司和已发布的职位，不受影响',
            okText: '继续',
            cancelText: '取消',
            onOk() {
                console.log('OK');
                _self.handleCloseAccount(data)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    async handleCloseAccount(data) {
        let res = await closeAccount(data)
        if (res.code === 0) {
            message.success('账号禁用成功!')
            this.handleAccountListSearch()
        } else {
            message.error('账号禁用失败!')
        }
    }

    async handleOpenAccount(id) {
        let res = await openAccount({ data: id })
        if (res.code === 0) {
            message.success('账号开启成功!')
            this.handleAccountListSearch()
        } else {
            message.error('账号开启失败!')
        }
    }

    render() {
        const { registerListArray, register, register2, accountListArray, columns,
            columns2, account , visible, modalForm} = this.state

        return (
            <div className='account-page'>
                <Tabs defaultActiveKey={'1'}>
                    <TabPane tab='注册申请' key='1'>
                        <Search
                            className='search-box'
                            placeholder="输入姓名/公司搜索"
                            enterButton="搜索"
                            size="large"
                            onSearch={value => this.handleSearchName(value)}
                        />
                        <Table
                            rowKey={(record) => record.id}
                            dataSource={registerListArray}
                            columns={columns}
                            pagination={false} />
                        <div className='pagination-box'>
                            {
                                register.totalRecord > 10 ?
                                    <Pagination
                                        defaultCurrent={register.pageNum}
                                        total={register.totalRecord}
                                        onChange={this.handleChange} />
                                    : null
                            }
                        </div>
                    </TabPane>
                    <TabPane tab='账号管理' key='2'>
                        <Search
                            className='search-box'
                            placeholder="输入姓名/公司搜索"
                            enterButton="搜索"
                            size="large"
                            onSearch={value => this.handleSearchName2(value)}
                        />
                        <Table
                            rowKey={(record) => record.id}
                            dataSource={accountListArray}
                            columns={columns2}
                            pagination={false} />
                        <div className='pagination-box'>
                            {
                                register2.totalPage > 1 ?
                                    <Pagination
                                        defaultCurrent={register2.pageNum}
                                        total={register2.totalRecord}
                                        onChange={this.handleChange2} />
                                    : null
                            }
                        </div>
                    </TabPane>
                </Tabs>
                {
                    visible ? <AccountForm parent={ this }  account={account} modalForm={modalForm} /> : null
                }
            </div>
        )
    }
}

export default Company